import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Input,Button} from "antd";
import BgAccount from "../Offset4rewards/BgAccount";
import api from "../../utils/api";
import util from "../../utils/util";

import  "../Offset4rewards/Offset4rewards.css";
import '../Offset4rewards/Scss.scss';

const FormItem = Form.Item;
class MyAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
        user:{
            name:"",
            lastname:"",
            email:""


            },
            dataUser: [],
            totalEgreen: 0,
        };
        
    }
  

    componentWillReceiveProps(nextProps) {
        console.log("componentWillReceiveProps", "=>", nextProps);
        if (nextProps) {
        }

    }
    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            console.log("componentDidUpdate", "=>", this.props);
        }
    }

   

    onChange = (e) => {
        console.log("radio checked", e.target.value);
        this.setState({
            radio: e.target.value,
        });
    };


    async componentDidMount() {
        document.title = "eGreen | My Profile";
        document.body.style.backgroundImage = "linear-gradient(to right, #0FA9C9, #5CC882)";
        window.scrollTo({ top: 0});

         let session=JSON.parse(localStorage.getItem("userSession"));
       this.setState({
          user:session

        });
        console.log(this.state.user);
        if(session){
            const dataUser = await api.user.getData({id:session.id});
            console.log(dataUser); //return;
            let temp = [];
        const totalEgreen = util.financial(dataUser.account.balances[0].balance);
        console.log(totalEgreen);
        dataUser.pretransaction.forEach(data => {
            let obj = {};
            obj.project = data.project_invested;
            temp.push(obj);
        })

        this.setState({dataUser: temp, totalEgreen: totalEgreen});

        }
        
        console.log("ente a componentDidMount ssssssssssssssssssssssssss", session);
    }



    render() {
        const { getFieldDecorator } = this.props.form;
            const childElements = this.state.dataUser.map((element, index) => {
                return (
                    <li className="col-group border-bottom color6 pointer" id="section5" onClick={this.props.getSection}>
                
                    <div className="col-6 color4 p-1 pb-m-1 t-l t-m-c ">
                        {element.project}
                    </div>
                    <div className="col-6 color4 p-1 pt-m-0 fw-500 fs-m-1_2em t-r t-m-c none-m">
                        <span class="material-icons color6 fs-0_8em">arrow_forward_ios</span>
                    </div>
                
                </li>
                );
              });
        return (
            <>

            <div className="general-container3">
                <div className="offset4rewards2 container">
                    {/* <StepComponent section={6}></StepComponent> */}
                    <div className="col-group ">

                        <div className="col-8 col-offset-2 pt-2 ">
                            <div className="container">
                                <div class="full-height-flex flex col-group box-shadow">
                                    <div className="col-6 bg4 col-mb-12 p-1 pt-3 pt-m-1">
                                        <div className="container">
                                            <div className="col-group">

                                                <div className="col-12 pb-2 pb-m-1 t-c fs-1_4em fw-500 color1">
                                                    {this.props.lan.Account.personal_data}
                                                </div>

                                                <div className="col-10 col-offset-1 p-0 pt-1">
                                                    <FormItem name="email" className="">
                                                        <div className="gx-mb-3">
                                                            {getFieldDecorator("name", {
                                                               initialValue: `${this.state.user.name}`,
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message:
                                                                            "Por favor, ingresa el name",
                                                                    },
                                                                ],
                                                            })(<Input placeholder={this.props.lan.Account.name} className="input-text color" disabled />)}
                                                        </div>
                                                    </FormItem>
                                                </div>
                                                <div className="col-10 col-offset-1 p-0 pt-1">
                                                    <FormItem name="email" className="">
                                                        <div className="gx-mb-3">
                                                            {getFieldDecorator("lastName", {
                                                                initialValue: `${this.state.user.lastName}`,
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message:
                                                                            "Por favor, ingresa el lastName",
                                                                    },
                                                                ],
                                                            })(<Input placeholder={this.props.lan.Account.lastname} className="input-text" disabled/>)}
                                                        </div>
                                                    </FormItem>
                                                </div>
                                                <div className="col-10 col-offset-1 p-0 pt-1">
                                                    <FormItem name="email" className="">
                                                        <div className="gx-mb-3">
                                                            {getFieldDecorator("email", {
                                                                initialValue: `${this.state.user.email}`,
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message:
                                                                            "Por favor, ingresa el Email",
                                                                    },
                                                                ],
                                                            })(<Input placeholder={this.props.lan.Account.email} className="input-text" disabled />)}
                                                        </div>
                                                    </FormItem>
                                                </div>
                                                
                                          {/*       <div className="col-10 col-offset-1 p-0 pt-1 pb-4">
                                                    <FormItem name="email" className="">
                                                        <div className="gx-mb-3">
                                                            {getFieldDecorator("email", {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message:
                                                                            "Por favor, ingresa el Email",
                                                                    },
                                                                ],
                                                            })(<Input placeholder="Repeat Password" className="input-text color1" />)}
                                                        </div>
                                                    </FormItem>
                                                </div> */}
                                                    <Button className="pointer btn-calculate p-0_5 border-radius-12 gradient3 box-shadow" onClick={this.props.logout}>{this.props.lan.Account.logout}</Button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="gradient3b col-6 col-mb-12 pt-3 pt-m-1">
                                        <div className="col-12 t-c fs-1_4em fw-500 pb-1 pb-m-1 color6">
                                            {this.props.lan.Account.your_current_EGRN}
                                        </div>

                                        <div className="container">
                                            <div className="col-group">

                                                <div className="col-12">

                                                    <div className="container border color6 border-radius-6">
                                                        <div className="col-group">
                                                            <div className="col-6 color4 p-1 pb-m-0 t-l t-m-c">
                                                            {this.props.lan.Account.eGRN_coin_obtained}
                                                            </div>
                                                            <div className="col-6 color4 p-1 pt-m-0 fw-500 fs-m-1_2em t-r t-m-c">
                                                                {this.state.totalEgreen}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div className="col-12 pt-1">
                                                    <div className="container">
                                                        <div className="col-group flex flex-center">
                                                            <Link
                                                                to='egrnxchange'
                                                                className="pointer t-c p-0_5 bg4 border-radius-6 fw-600 color3 box-shadow">
                                                                egrnXchange
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-12 t-c fs-1_4em fw-500 pt-2 pt-m-1 pb-1 pb-m-1 color6">
                                            {this.props.lan.Account.carbon_credit_invested}
                                        </div>

                                        <div className="container pb-4">
                                            <div className="col-group">
                                                <div className="col-12 color6 pt-0 pr-2 fs-1em ">
                                                    <span className="flex flex-end align-item-center">
                                                        {/* <span className="color6 pr-1">Add New</span> */}
                                                        {/* <span class="material-icons color6 pointer" id="section3" onClick={this.props.getSection}>add_box</span> */}
                                                    </span>
                                                </div>
                                                <div className="col-12">
                                                    <ul className="container scroll-invested border color6 border-radius-6">
                                                    {childElements}
                                                    </ul>
                                                    
                                                </div>


                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <BgAccount />
            </div>
            </>
        );
    }
}
const Section1 = Form.create()(MyAccount);
export default Section1;
