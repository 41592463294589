import React, { Component } from "react";
import api from "../../utils/api";
import { Form, Radio } from "antd";
import  "../Offset4rewards/Offset4rewards.css";
import { loadStripe } from '@stripe/stripe-js';
import '../Offset4rewards/Scss.scss';
import BgClouds from './BgClouds';
import Breadcrumbs from './Breadcrumbs';
import moment from "moment";
import util from "../../utils/util";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY_STRIPE);

class Offset4rewards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataFlight: this.props.dataFlight,
            co2_enter: this.props.co2_enter,
            date: "",
            radio: 1,
            project: this.props.projects[this.props.selectProject],
            amount: this.props.amount,
            egreenCoin: util.financial(this.props.egreenCoin),
            fee: '',
            total: '',
            iconCard: true,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        console.log("componentWillReceiveProps", "=>", nextProps);
        if (nextProps) {
            this.setState({});
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            console.log("componentDidUpdate", "=>", this.props);
        }
    }

    handleInputChange(event) {
        const target = event.target;
        console.log(target.name);
        const value = target.name === "oneWay" ? target.checked : target.value;
        console.log(target.value);
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log("Received values of form: ", values);
            }

        });
    };


    onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };


    componentDidMount() {
        console.log(this.state.egreenCoin);
        document.title = "eGreen | Offset4reward - Payment & Reward";
        document.body.style.backgroundImage = "linear-gradient(to right, #A7F5FF, #EDF6F5)";
        window.scrollTo({ top: 0});
        console.log(this.state.amount);
        const fee = parseFloat(util.financial((this.state.amount * parseFloat(process.env.REACT_APP_FEE))));
        console.log(fee);
     
        this.setState({fee: fee});
        this.setState({total:this.state.amount + fee });
    }

    handleClick = async (event) => {

        console.log(this.state.project);
        console.log(this.state.co2_enter);
        const dataFlight = this.state.dataFlight;
        const user =JSON.parse(localStorage.getItem("userSession"));
        console.log(user);
        console.log(this.state.egreenCoin * process.env.REACT_APP_CLIENT_EGREEN);
        console.log(this.state.egreenCoin * process.env.REACT_APP_PARTNER_EGREEN);

        // save pretrasaction
        let obj = {};
        obj.activity_id =  "dfjr-44fr-flight";
        obj.partner_id =  process.env.REACT_APP_ID_PARTNER;
        if(dataFlight.co2){
            obj.co2_emission = (dataFlight.co2 / 1000);
        }
        if( this.state.co2_enter){
            obj.co2_emission = (this.state.co2_enter);
        }
        obj.aircraft_type = dataFlight.aircraft;
        obj.airline =  dataFlight.airline;
        obj.flight = dataFlight.flight;
        obj.distance = dataFlight.distance;
        obj.total_coins = this.state.egreenCoin;
        obj.client_coins = this.state.egreenCoin * process.env.REACT_APP_CLIENT_EGREEN;
        obj.partner_coins = this.state.egreenCoin * process.env.REACT_APP_PARTNER_EGREEN;
        obj.carbon_credit_invested = obj.co2_emission;
        obj.project_invested = this.state.project.title;
        obj.payment_amount = util.financial(this.state.total);
        obj.date = moment().format("DD-MM-YYYY hh:mm:ss");
        obj.id_user = user.id;
        obj.project_id = this.state.project.id;
        console.log(obj);
        
        const pretransaction = await api.pretransaction.savePretransaction(obj);
        console.log(pretransaction);
        // Get Stripe.js instance
        const stripe = await stripePromise;
        const params = {
            pay: this.state.total * process.env.REACT_APP_MONEY_FACTOR,
            offset: "Offset CO2",
            id_pretransaction: pretransaction.id,
          };
        // Call your backend to create the Checkout Session
        const response = await api.stripe.pay(params);
        const session = response;
        console.log(session);
    
        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });

        console.log(result);
    
        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        }
      };

    render() {

        return (                                
            <>  
            <Breadcrumbs iconCo2={this.props.iconCo2} iconTree={this.props.iconTree} iconCard={this.state.iconCard} />
            <div className="general-container3">
                <div className="offset4rewards no-w-100 container">
                    {/* <StepComponent section={5}></StepComponent> */}
                    <div className="col-group ">

                        <div className="col-6 col-offset-3 pt-2 ">
                            <div className="container"> 
                                <div class="full-height-flex col-group flex bg4 box-shadow">
                                    <div className="col-6 gradient3 col-mb-12 p-1 pt-2 pt-m-1">

                                        <div className="container">
                                            <div className="col-group">
                                                {/* <div className="col-12 t-c pb-2">
                                                    <img src="/svg/login.svg" width="60%" />
                                                </div> */}
                                                <div className="col-12 t-c pb-1 pb-m-0 fs-1_4em fw-500 color4">
                                                    {this.props.lan.Payment.payment_and_reward}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container">
                                            <div className="col-group">
                                                <div className="col-12 color6 pt-2 uppercase fs-0_8em">
                                                    {this.props.lan.Payment.payment}
                                                </div>
                                                <div className="col-12">

                                                    <div className="container border color6 border-radius-6">
                                                        <div className="col-group">
                                                            <div className="col-6 color4 p-1 pb-m-0 t-l t-m-c">
                                                                {this.props.lan.Payment.amount}:
                                                            </div>
                                                            <div className="col-6 color4 p-1 pt-m-0 fw-500 fs-m-1_2em t-r t-m-c">
                                                                {this.state.amount} USD
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="container">
                                            <div className="col-group">
                                                <div className="col-12 color6 pt-2 uppercase fs-0_8em">
                                                    {this.props.lan.Payment.rewards}
                                                </div>
                                                <div className="col-12">

                                                    <div className="container border color6 border-radius-6">
                                                        <div className="col-group">
                                                            <div className="col-6 color4 p-1 pb-m-0 t-l t-m-c">
                                                                {this.props.lan.Payment.eGRN_coins_allocated}:
                                                            </div>
                                                            <div className="col-6 color4 p-1 pt-m-0 fw-500 fs-m-1_2em t-r t-m-c">
                                                                {this.state.egreenCoin}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container">
                                            <div className="col-group">
                                                <div className="col-12 color6 pt-2 uppercase fs-0_8em">
                                                    {this.props.lan.Payment.payment_method}
                                                </div>
                                                <div className="col-12 pb-2">

                                                    <div className="container border color6 border-radius-6">

                                                            <Radio.Group className="payment-method col-group" name="traveltype" defaultValue="credit-card">
                                                                <Radio className="col-12 color4 p-1 pb-0 t-l" value="credit-card">{this.props.lan.Payment.credit_card}</Radio>
                                                                {/* <Radio className="col-12 color4 p-1 t-l" value="bank-transfer">Bank Transfer</Radio> */}
                                                            </Radio.Group>

                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-6 col-mb-12 p-1 pt-2 p-m-1 bg4">
                                        <div className="col-12 t-c fs-1_4em fw-500 p-m-1 pb-1 color1">
                                                {this.props.lan.Payment.payment_method}
                                        </div>

                                        <div className="col-12 p-0 pt-1 flex flex-wrap flex-space-around">
                                            {/* <img src="/svg/payment/visa.svg" />
                                            <img src="/svg/payment/mastercard.svg" /> */}
                                            {/* <img src="/svg/payment/paypal.svg" /> */}
                                        </div>

                                        <div className="col-12 p-0">
                                            <div className="container">
                                                <div className="col-group">
                                                    <div className="col-12 color7 pt-2 uppercase fs-0_8em">
                                                        {this.props.lan.Payment.payment}
                                                    </div>
                                                    <div className="col-12">
    
                                                        <div className="container border color7 border-radius-6">
                                                            <div className="col-group">
                                                                <div className="col-6 color3 p-1 pb-0 pb-m-0 t-l t-m-c">
                                                                    CO2:
                                                                </div>
                                                                <div className="col-6 color3 p-1 pb-0 pt-m-0 fw-500 fs-m-1_2em t-r t-m-c">
                                                                    {this.state.amount} USD
                                                                </div>
                                                            </div>
                                                            <div className="col-group">
                                                                <div className="col-6 color3 p-1 pb-0 pb-m-0 t-l t-m-c">
                                                                    Fee:
                                                                </div>
                                                                <div className="col-6 color3 p-1 pb-0 pt-m-0 fw-500 fs-m-1_2em t-r t-m-c">
                                                                {this.state.fee} USD
                                                                </div>
                                                            </div>
                                                            <div className="col-group">
                                                                <div className="col-6 color3 p-1 pb-m-0 t-l t-m-c">
                                                                    Total:
                                                                </div>
                                                                <div className="col-6 color3 p-1 pt-m-0 fw-500 fs-m-1_2em t-r t-m-c">
                                                                {this.state.total} USD
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-12 p-0 pt-1">
                                            <Form.Item className="p-0">
                                                {/* <div className="pointer btn-calculate p-0_5 border-radius-12 gradient3 box-shadow t-c color4" id="section8" onClick={this.props.getSection} >Payment</div> */}
                                                    <div className="btn-disabled btn-calculate p-0_5 border-radius-12 gradient3 box-shadow t-c bg4 color4"  disabled="disabled">{this.props.lan.Payment.payment}</div>
                                            </Form.Item>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <BgClouds />
            </div>
            </>
        );
    }
}
const Section1 = Form.create()(Offset4rewards);
export default Section1;
