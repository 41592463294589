import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Masonry from 'react-masonry-component';
import  '../Offset4rewards/Offset4rewards.css';
import Breadcrumbs from './Breadcrumbs';

class Offset4rewards extends Component {

    constructor(props) {
        super(props);
        this.state = {
            flight: '',
            date: '',
            radio: 1,
            result: {},
            projects: this.props.projects,
            selectProject: this.props.selectProject,
            iconTree: true,

        };
        // this.handleInputChange = this.handleInputChange.bind(this);
    }

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    componentDidMount() {
        document.title = "eGreen | Offset4reward - Green Projects";
        document.body.style.backgroundImage = "linear-gradient(to right, #0FA9C9, #5CC882)";
        console.log(this.props.projects);
        console.log(this.props.selectProject);

        // this.setState({ selectProject: this.props.selectProject });
        window.scrollTo({ top: 0});

    }


    onSelect = e => {
        e = e || window.event;
        e = e.target || e.srcElement;
        console.log(e.id);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.setState({ selectProject:e.id });
        this.props.saveSelectProject(e.id);
    };


    render() {

        const childElements = this.state.projects.map((element, index) => {
            return (
                // <Link to={element.link} key={index} className="green-project col-4 col-mb-12 p-0_5 p-m-2 pt-m-0 pb-m-0">
                <Link data-keyclick={index} id="Section4" key={index} className="green-project col-4 col-mb-12 p-0_5 p-m-2 pt-m-0 pb-m-0">
                    <div className="col-12 p-1 bg4 box-shadow">
                        <div className="col-12 max-height-m-8 p-0 block hidden pb-0_5 pb-m-0_5">
                            <img src={element.src[0].url} className="w-100 max-height-12 max-height-m-8 p-0 cover" />
                        </div>
                        <h3 className="">{element.title}</h3>
                        <p className="pb-1">{element.text}</p>
                   
                        <Link onClick={this.onSelect} className=" t-c p-0_5 bg1 border-radius-12  color4 fs-0_8em" name={index} id={index}   >{this.props.lan.projects.see_details}</Link>
                    </div>
                    {/* <p>{index}</p> */}

                </Link>
            );
        });


        const selectChildElements = this.state.projects.map((element, index) => {
            if (Number(this.state.selectProject) === index) {
                return (
                    <div className="bg-project col-12 p-4 p-m-1" style={{ backgroundImage: `url(${element.src[1].url})` }}>
                        <div className="container" >
                            <div className="col-group">
                                <div className="col-3 col-offset-2 p-0" >
                                    <div className="color4 fs-2em fs-m-1_8em fw-500 t-m-c p-m-0_5">
                                       {element.title}
                         
                                    </div>
                                    <div className="color4 fs-1_2em fs-m-1em fw-500 t-m-c p-m-1 pt-m-0">
                                    {element.text}
                                  
                                    </div>
                                </div>
                                <div className="col-3 col-offset-2 p-0">
                                    <div className="p-0_5">
                                        <div className="p-1 bg4 border-radius-12 box-shadow">
                                            <div className="w-100 t-c pb-0_5">
                                                <img src="/svg/project.svg" width="70" />
                                            </div>

                                            <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                                <div className="col-6 color1 fw-400 t-l t-c-m">
                                                    {this.props.lan.projects.area}:{" "}
                                                </div>
                                                <div className="col-6 color1 fw-600 fs-m-1_2em t-r t-c-m">
                                                    {element.area} ha
                                                </div>
                                            </div>

                                            <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                                <div className="col-6 color1 fw-400 t-l t-c-m">
                                                    {this.props.lan.projects.biomass}:{" "}
                                                </div>
                                                <div className="col-6 color1 fw-600 fs-m-1_2em t-r t-c-m">
                                                   {element.biomass} t/ha
                                                </div>
                                            </div>

                                            <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                                <div className="col-6 color1 fw-400 t-l t-c-m">
                                                    {this.props.lan.projects.ton_cO2_issued}:{" "}
                                                </div>
                                                <div className="col-6 color1 fw-600 fs-m-1_2em t-r t-c-m">
                                                    {element.ton_co2_issued} t
                                                </div>
                                            </div>

                                            <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                                <div className="col-6 color1 fw-400 t-l t-c-m">
                                                    {this.props.lan.projects.ton_price}:{" "}
                                                </div>
                                                <div className="col-6 color1 fw-600 fs-m-1_2em t-r t-c-m">
                                                {element.ton_price} USD
                                                </div>
                                            </div>

                                            <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                                <div className="col-12 t-c-m pt-1">
                                                    <Link
                                                      
                                                        id="section5"
                                                        onClick={this.props.getSection}
                                                        className="col-12 t-c p-0_5 bg3 border-radius-6 fw-600 color4 box-shadow">
                                                        {this.props.lan.projects.invest}
                                                    </Link>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        });
        return (
            <>
            <Breadcrumbs iconCo2={this.props.iconCo2} iconTree={this.state.iconTree} />
            <div className="general-container2" >

                <div className=" container">
                    <div className="col-group">
                    {/* <StepComponent section={3}></StepComponent> */}
                        <div className="col-12 flex">
                            {/* <Link onClick={this.props.getSection} className=" t-c p-0_5 bg1 border-radius-12  color4 fs-0_8em active" id="section1" >seccion1</Link>
                            <Link onClick={this.props.getSection} className=" t-c p-0_5 bg1 border-radius-12  color4 fs-0_8em" id="section3" >seccion2</Link>
                            <Link onClick={this.props.getSection} className=" t-c p-0_5 bg2 border-radius-12  color4 fs-0_8em" id="section4" >seccion3</Link> */}
                           {/*  <Link onClick={this.getSection} className=" t-c p-0_5 bg2 border-radius-12  color4 fs-0_8em" id="section4" >seccion4</Link> */}
                           
                        </div>
                        {selectChildElements}

                        <div className="col-offset-2 col-4 col-mb-12 pt-2 p-m-1 pb-m-0_5 fs-1_6em fs-m-1_2em fw-500 t-c-m color4">
                            {this.props.lan.projects.tittle1}
                            </div>
                        {/* masonry */}
                        <Masonry className={"col-offset-2 col-8 col-mb-12 p-0 pb-4 hidden"}>
                            {childElements}
                        </Masonry>
                        {/* fin Masonry */}
                    </div>
                </div>

            </div>
            </>
        )
    }
}

export default Offset4rewards
