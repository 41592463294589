import React, { Component } from "react";
import { Form, Input, Button } from "antd";
import api from "../../utils/api";



const FormItem = Form.Item;
class Offset4rewards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: { show: false, message: '' },cargando:false
        };
    }

    componentDidMount = () => {

        /*    let userSession ={
               email : values.email1,
               password : values.password1,
               token : values.token,
               refreshToken : values.refreshToken,
               name:"TEST",
               lastName:"TEST"
           } */
        let userSession = localStorage.getItem('userSession');//RECAORDAR SACAR EL JSON PARA QUE SE OCULTE EL OBJETO EN EL NAVEGADOR 



    }

    register = (e) => {
        e.preventDefault();
        // return;
        console.log(this.props.form);
     
        this.setState({ error: { show: false, message: "" },cargando:true});
        this.props.form.validateFields(async (err, values) => {

            if (!err) {
                
                if (values.password2 === values.passwordRepeat) {
                    console.log("Received values of form: ", values);
                    let objet = {};
                    objet.name = values.name;
                    objet.lastname = values.lastname;
                    objet.email = values.email2;
                    objet.password = values.password2;
                    objet.type = 'person';
                   
                    const result = await api.user.register(objet);
                    console.log(result);
                    console.log(result.status);
                    if (result.status != 404 && result.status != 500) {

                        console.log(values.email2);
                        let obj = {};
                        obj.destiny = values.email2;
                        obj.language = 'ing';
                        obj.type = 'welcome';
                        obj.pretransaction = {};
                        console.log(obj);
                        const sendEmail = api.email.send(obj);
                    }
                    
                    
                   console.log("result", result); 
                     if(result.message){
                        this.setState({ error: { show: true, message: result.message },cargando:false});
                      }else{ 
                        let message=" ";
                        let data={};
                         data= JSON.stringify(result.errors);

                         result.errors.map((element) => {

                            if(element.password)
                            {
                                message=message+`Password:${element.password} \n`;
                            }
                            if(element.email)
                            {
                                message=message+`Email:${element.email} \n`;
                            }
                           
                         });
                    this.setState({ error: { show: true, message: message } ,cargando:false});
                     } 
                }
                else {
                    this.setState({ error: { show: true, message: "passwords do not match" } ,cargando:false});
                }
            }
        else{
            this.setState({cargando: false});
            
        }
        });
    /*     this.setState({cargando: false}); */
    }

    render() {
        const getFieldDecorator2 = this.props.form.getFieldDecorator;

        return (
            <>

                <div className="gradient3b col-7 col-mb-12 p-2 p-m-1">
                    <div className="col-12 t-c fs-2em fw-500 pb-1 color6">
                        {this.props.lan.Account.create_account}
                                        </div>
                    <Form name="createAccouut" >
                        <div className="col-10 col-offset-1 p-0 pt-1">
                            <FormItem name="name" className="">
                                <div className="gx-mb-3">
                                    {getFieldDecorator2("name", {

                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    "Please, enter the name",
                                            },
                                        ],
                                    })(<Input placeholder={this.props.lan.Account.name} className="input-text color4" />)}
                                </div>
                            </FormItem>
                        </div>
                        <div className="col-10 col-offset-1 p-0 pt-1">
                            <FormItem name="lastname" className="">
                                <div className="gx-mb-3">
                                    {getFieldDecorator2("lastname", {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    "Please, enter the last name",
                                            },
                                        ],
                                    })(<Input placeholder={this.props.lan.Account.last_name} className="input-text color4" />)}
                                </div>
                            </FormItem>
                        </div>
                        <div className="col-10 col-offset-1 p-0 pt-1">
                            <FormItem name="email2" className="">
                                <div className="gx-mb-3">
                                    {getFieldDecorator2("email2", {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    "Please, enter the email",
                                            },
                                        ],
                                    })(<Input placeholder={this.props.lan.Account.email} className="input-text color4" />)}
                                </div>
                            </FormItem>
                        </div>
                        <div className="col-10 col-offset-1 p-0 pt-1">
                            <FormItem name="password2" className="">
                                <div className="gx-mb-3">
                                    {getFieldDecorator2("password2", {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    "Please, enter the password",
                                            },
                                        ],
                                    })(<Input placeholder={this.props.lan.Account.password} className="input-text color4" type="password" />)}
                                </div>
                            </FormItem>
                        </div>
                        <div className="col-10 col-offset-1 p-0 pt-1">
                            <FormItem name="passwordRepeat" className="">
                                <div className="gx-mb-3">
                                    {getFieldDecorator2("passwordRepeat", {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    "Please, repeat the password",
                                            },
                                        ],
                                    })(<Input placeholder={this.props.lan.Account.repeat_password} className="input-text color4" type="password" />)}
                                </div>
                            </FormItem>
                        </div>

                        <div className="col-12 p-0 pt-1">
                            <Form.Item className="p-0">
                                <Button className="pointer btn-calculate p-0_5 border-radius-12 bg4 box-shadow color1" htmlType="submit" onClick={this.register}>{this.props.lan.Account.register}</Button>
                            </Form.Item>
                        </div>

                        {  this.state.cargando===true ? (
                        <div className="col-offset-2 col-8 col-mb-12 p-0 pt-4 position-relative">
                          
                          <div id="loader" class="register loader" >
                            <div class="loader-inner ball-scale-ripple-multiple" >
                             
                              <div></div>
                              <div></div> 
                            </div>
                          </div>
                        {/*    <div className="loading-text color4 t-c">Loading Projects</div>  */}
                        </div>
                        ):''}

                        { this.state.error.show ? (  
                        <div className="col-12 p-0 pt-1">
                            <div className="register error-msg t-c fs-0_8em">{this.state.error.message}</div>
                        </div>
                        ) : ('')
                    }

                    </Form>


                </div>

            </>
        );
    }
}
const Login = Form.create()(Offset4rewards);
export default Login;
