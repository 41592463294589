import React, { Component } from "react";
import { Link } from "react-router-dom";


import Offset4rewardsCSS from "../Offset4rewards/Offset4rewards.css";
import '../Offset4rewards/Scss.scss';

class Offset4rewards extends Component {
    constructor(props) {
        super(props);
    };

    componentDidMount() {
        document.body.style.backgroundImage = "linear-gradient(to right, #0FA9C9, #5CC882)";
    }

    render() {

        return (
            <div className="general-container">
                <div className=" container">
                    <div className="col-group ">
                        <div className="col-6 col-offset-3 pt-2 t-c ">
                            <span className="material-icons fs-2_6em color4">error_outline</span>
                            <div className="fs-1_4em fw-700 color4">
                                OOPS!!
                            </div>
                            <div className="pb-0_5 fs-1_2em fw-700 color4">
                                Your transaction was not accepted.
                            </div>
                        </div>
                    </div>

                    <div className="col-group ">
                        <div className="col-6 col-offset-3 pt-2 t-c ">
                            <div className="register error-msg">
                                <div className="tomato fs-0_8em p-0_5">
                                    The operation could not be carried out, because you canceled the payment process
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-group ">
                        <div className="col-6 col-offset-3 pt-2 t-c">
                            <div className=" fs-1em color4 fw-700">
                                Please,
                            </div>
                            <div className="pb-2 fs-1em color4">
                                Check with your credit card issuer or try again with another credit card here:
                            </div>
                            <Link to="/myaccount" className="bg4 color1 p-0_5 pl-2 pr-2 fs-0_8em fw-700 border-radius-12">
                                Edit Credit Card
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="monodepie1"></div>
                <div className="monodepie2"></div>
            </div>
        );
    }
}
export default Offset4rewards
