import React, { Component } from "react";
import api from "../../utils/api";
import { Form, Input, Button, Radio, Tooltip } from "antd";
import BgClouds from "./BgClouds";
import "../Offset4rewards/Offset4rewards.css";
import util from "../../utils/util";
import moment from 'moment';
import  ReCAPTCHA  from "react-google-recaptcha" ; 

import Breadcrumbs from './Breadcrumbs';

const FormItem = Form.Item;

class Offset4rewards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flight: "",
      date: "",
      radio: 1,
      result: {},
      error: { show:false, message: ''},
      disabled : true,
      validateSubmit : false,
      iconCo2: false,
      spiner:false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onChange = this.onChange.bind(this);
    // this.getSection = "section1";
    console.log(this.props.lan);
  }

  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps", "=>", nextProps);
    if (nextProps) {
      this.setState({});
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      console.log("componentDidUpdate", "=>", this.props);
    }
  }

  handleInputChange(event) {
    const target = event.target;
    console.log(target.name);
    const value = target.name === "oneWay" ? target.checked : target.value;
    console.log(target.value);
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }


  handleSubmit = (e) => {
    this.cargando = true;
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({spiner:true,error: {show: false}});
        let resultObj = {};
        console.log("Received values of form: ", values);
        const params = {
          flight: values.flight,
          date: values.date
        };
        const response = await api.flight.saveCo2(params);
        if(response.co2_register.data.hasOwnProperty('error')){
          
          this.setState({error: {show: true, message: response.co2_register.data.error },spiner:false,result:{}});
          setTimeout(
            function() {
                this.setState({ error: {show: false} });
            }
            .bind(this),
            10000
          );
          return;
        }
        this.setState({error: {show: false, message: '' }});
        this.setState({disabled: false });
        resultObj.flight = values.flight;
        resultObj.date = moment(values.date).format('DD-MM-YYYY');
        console.log(response);
        resultObj.airline = response.co2_register.data.airline.name || 'N/A';
        resultObj.destiny = response.co2_register.data.origin_airport.city_name+ ' - ' + 
        response.co2_register.data.destination_airport.city_name ;
        resultObj.distance = util.redondeoUp(response.co2_register.data.kilometers_traveled);
        resultObj.aircraft = response.co2_register.data.airplane.name;
        resultObj.co2 = util.redondeoUp(response.co2_register.co2_calculation[0].resolved);
        this.setState({ result: resultObj, iconCo2: true,spiner:false});
  
        this.props.saveDataFlight(resultObj);
      }

      this.setState({spiner:false});
    });
  };

  handleSubmit2(event) {
    event.preventDefault();
    console.log(event);
    console.log(this.state.flight);
    console.log(
      "A name was submitted: " + this.state.flight + " - " + this.state.flight
    );
  }



  componentDidMount() {
    console.log(util.financial(15.7664345));
    console.log(util.redondeoUp(15.7664345,2));
    const amount = 1.56;
    const fee = util.financial((amount * parseFloat(process.env.REACT_APP_FEE)), true);
    console.log(fee);
    
    document.title = "eGreen | Offset4reward";
    document.body.style.backgroundImage = "linear-gradient(to right, #A7F5FF, #EDF6F5)";
    window.scrollTo({ top: 0});
    console.log(this.getSection);
  }

  onChange = (value) => {
    console.log("Captcha value2:", value);

    return new Promise((resolve, reject) => { 

      if(value != null){
        this.setState({validateSubmit: true});
        console.log('cambiamos a true');
        resolve();
      }else{
        this.setState({validateSubmit: false});
        console.log('cambiamos a false');
        reject();
      }
     
  
    }); //end promise
    
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    // let submitClassValidation = '';
    // if(this.props.validateSubmit){
    //   submitClassValidation += 'btn-disabled';
    // }

    return (
      <>
      <Breadcrumbs iconCo2={this.state.iconCo2}  />
      <div className="general-container">
        <div className="offset4rewards container p-0">
        {/* <StepComponent section={1}></StepComponent> */}
          <div className="col-group pt-2 pt-m-1">
            <div className="col-offset-2 col-4 col-mb-12 p-1 p-m-1 pt-m-0_5">
              <div className="col-12 fs-2em fs-m-1_6em fw-500 t-c-m color1">
                  {this.props.lan.Offset4rewards.tittle1}
              </div>

              <div className="col-12 pt-0_5 fs-2em fs-m-1_6em fw-500 color1">
                
                <div className="col-6 p-0_5 pr-0 pl-m-0 pr-m-0 pt-0_2 pt-m-0 pb-0_2">
                  <div
                    id="section2"
                    className="pointer btn-link-offset4rewards t-c p-0_2 bg5"
                    onClick={this.props.getSection}
                  >
                      {this.props.lan.Offset4rewards.impact_investment}
                  </div>
                </div>

                  <div className="col-6 p-0_5 pr-0 pl-m-0 pr-m-0 pt-0_2 pt-m-0 pb-0_2">
                  <span className="btn-link-offset4rewards active t-c p-0_2 bg5">
                      {this.props.lan.Offset4rewards.airlines}
                </span>
                </div>

              </div>

              <div className="col-12 p-4 p-m-0_5 none-m position-relative">
                {/* <img className="avion" src="/svg/avion-b.svg"></img> */}

                {/* {  this.cargando ? (
                          <>
                            <div id="loader" class="loader">
                              <div class="loader-inner ball-scale-ripple-multiple">
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </>
                        ):''} */}

              </div>
            </div>
            <div className="col-4 col-mb-12 p-0">
              <div className="container">
                <div className="col-group">
                  <div className="col-12 p-1 p-m-2 pt-m-0">
                    <div className="container">
                      <div className="col-group">
                        <div className="col-12 p-2 p-m-2 pt-m-1 bg4 border-radius-12 box-shadow">
                          <div className="t-c fw-500 pt-0 pb-2 pb-m-1 fs-1_2em">
                              {this.props.lan.Offset4rewards.my_flight}
                          </div>


                          <Form name="MyFlight" className="" onSubmit={this.handleSubmit}>
                            
                              <FormItem label={this.props.lan.Offset4rewards.flight_no +String.fromCharCode(58)} name="flight" className="fua">
                                <Tooltip title="LA193">
                                <div className="gx-mb-3">
                                  {getFieldDecorator("flight", {
                                    rules: [
                                      {
                                        required: true,
                                        message:
                                          "Please, enter the flight!",
                                      },
                                    ],
                                  })(<Input placeholder="LAN193" className="input-text" />)}
                                </div></Tooltip>
                              
                              </FormItem>
                              <FormItem label={this.props.lan.Offset4rewards.date + String.fromCharCode(58)} name="date" className="fua">
                              <div className="gx-mb-3">
                                {getFieldDecorator("date", {
                                  rules: [
                                    {
                                      required: true,
                                      message:
                                        "Please, enter the date!",
                                    },
                                  ],
                                })(<Input type="date" placeholder="Date" className="input-text" />)}
                              </div>
                            </FormItem>

                            <Radio.Group name="traveltype" defaultValue="one-way">
                                <Radio value="one-way">{this.props.lan.Offset4rewards.one_way}</Radio>
                              {/* <Radio value="round-trip">Round Trip</Radio> */}
                            </Radio.Group>

                            <Form.Item className="p-0 pt-1">
                              { this.state.validateSubmit ? (
                                  <Button className="pointer btn-calculate p-0_5 border-radius-12 gradient3 box-shadow" htmlType="submit">{this.props.lan.Offset4rewards.calculate}</Button>
                              )
                              : (
                                    <Button className="btn-disabled btn-calculate p-0_5 border-radius-12 gradient3 box-shadow" htmlType="submit" disabled>{this.props.lan.Offset4rewards.calculate}</Button>
                              )
                              }
                            </Form.Item>
                            { this.state.error.show ? (
                            <div className="error-msg t-c fs-0_8em">Bad Request - {this.state.error.message} </div>
                            ) : (<div></div>)
                            }

                          <Form.Item className="p-0 pt-1">
                            <center><ReCAPTCHA
                              sitekey={process.env.REACT_APP_PUBLICK_KEY_CAPTCHA}
                              onChange={ this.onChange }
                            />
                            </center>
                          </Form.Item>

                          </Form>
                          
                          
                                


                          <div className="container">
                            <div className="col-group p-0 pt-1">
                              <div className="col-12 p-1 gradient2 border-radius-6">
                                <div className="container p-0">
                                 
                                 {!this.state.spiner?
                                  <>
                                  <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                    <div className="col-5 color4 fw-400 t-c-m">
                                        {this.props.lan.Offset4rewards.airline}:{" "}
                                    </div>
                                    <div className="col-7 color4 fw-600 fs-m-1_2em t-c-m">
                                    {this.state.result.airline}
                                    </div>
                                  </div>
                                  <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                    <div className="col-5 color4 fw-400 t-c-m">
                                        {this.props.lan.Offset4rewards.destiny}:{" "}
                                    </div>
                                    <div className="col-7 color4 fw-600 fs-m-1_2em t-c-m">
                                      {this.state.result.destiny}
                                    </div>
                                  </div>
                                  <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                    <div className="col-5 color4 fw-400 t-c-m">
                                        {this.props.lan.Offset4rewards.distance}:{" "}
                                    </div>
                                    <div className="col-7 color4 fw-600 fs-m-1_2em t-c-m">
                                      {this.state.result.distance}
                                      { this.state.result.distance ? (" Kms" ) : ("")}  
                                    </div>
                                  </div>
                                   
                                  <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                    <div className="col-5 color4 fw-400 t-c-m">
                                      {this.props.lan.Offset4rewards.aircraft}:{" "}
                                    </div>
                                    <div className="col-7 color4 fw-600 fs-m-1_2em t-c-m">
                                      {this.state.result.aircraft}
                                    </div>
                                  </div>
                                  <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                    <div className="col-5 color4 fw-400 t-c-m">
                                        {this.props.lan.Offset4rewards.co2_emissions}:{" "}
                                    </div>
                                    <div className="col-7 color4 fw-600 fs-m-1_2em t-c-m">
                                      {this.state.result.co2} 
                                      { this.state.result.co2 ? (" CO2 Kgs" ) : ("")} 
                                    </div>
                                  </div>
                                  { this.state.disabled ? ('') : 
                                  ( <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                  <div className="col-12 t-c-m pt-1">
                                    <button
                                      id="section3"
                                      disabled={this.state.disabled}
                                      onClick={this.props.getSection}
                                      name={this.state.iconCo2.toString()}
                                      className="pointer col-12 t-c p-0_5 bg4 border-radius-6 fw-600 color3 box-shadow">
                                      {this.props.lan.Offset4rewards.see_green_projects}
                                    </button>
                                  </div>
                                </div>)
                                  }
                                  
                                  </>
                                 :
                                 <>



                        <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                        
                        <div className="col-offset-2 col-8 col-mb-12 p-0 pt-4 position-relative">
                          
                          <div id="loader" class="loader">
                            <div class="loader-inner ball-scale-ripple-multiple">
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                          <div className="loading-text color4 t-c">Calculating ...</div>
                        </div>
                                    <div className="col-5 color4 fw-400 t-c-m">
                                     
                                    </div>
                                    <div className="col-7 color4 fw-600 fs-m-1_2em t-c-m">
                                  
                                    </div>
                                  </div>
                                  <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                    <div className="col-5 color4 fw-400 t-c-m">
                                  
                                    </div>
                                    <div className="col-7 color4 fw-600 fs-m-1_2em t-c-m">
                                    
                                    </div>
                                  </div>
                                  <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                    <div className="col-5 color4 fw-400 t-c-m">
                                   
                                    </div>
                                    <div className="col-7 color4 fw-600 fs-m-1_2em t-c-m">
                                     
                                    </div>
                                  </div>
                                   
                                  <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                    <div className="col-5 color4 fw-400 t-c-m">
                                   
                                    </div>
                                    <div className="col-7 color4 fw-600 fs-m-1_2em t-c-m">
                                     
                                    </div>
                                  </div>
                                  <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                    <div className="col-5 color4 fw-400 t-c-m">
                                      
                                    </div>
                                    <div className="col-7 color4 fw-600 fs-m-1_2em t-c-m">
                                    
                                    </div>
                                  </div>
                                  <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                    <div className="col-12 t-c-m pt-1">
                                   
                                    </div>
                                  </div>
                                   
                                   </>                                                                 
                                 }
                               
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BgClouds />
      </div>
      </>
    );
  }
}
const Section1 = Form.create()(Offset4rewards);
export default Section1;
