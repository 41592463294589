import React from 'react'

const EgrnXchange = () => {
    return (
        <div>
            egrnXchange
        </div>
    )
}

export default EgrnXchange
