import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../Offset4rewards/Offset4rewards.css";
import '../Offset4rewards/Scss.scss';

class Offset4rewards extends Component {
    constructor(props) {
        super(props);
        this.state= {
            pretransaction: this.props.pretransaction,
        }
    };

    componentDidMount() {
        document.body.style.backgroundImage = "linear-gradient(to right, #0FA9C9, #5CC882)";
        console.log(this.state.pretransaction);
    }

    render() {

        return (
            <div className="general-container">
                <div className=" container">
                    <div className="col-group ">
                        <div className="col-6 col-offset-3 pt-2 t-c">
                            <div className="pb-1 fs-1_4em fw-700 color4">
                                Great News!!
                            </div>
                            <div className="pb-0_5 fs-1em color4">
                                Your transaction was fully stored at our blockchain (DTL) secure and transparently.
                            </div>
                            <div className="pb-2 fs-1em color4">
                                Thank you for your support!
                            </div>
                        </div>
                    </div>

                    <div className="col-group p-0">
                        <div className="col-4 col-offset-4">
                            <div className="container">
                                <div className="col-group p-0_5 p-m-0_5 border color6">
                                    <div className="col-5 color4 fw-400 t-c-m t-l">
                                        Id Trasaction:
                                    </div>
                                    <div className="col-7 color4 fw-600 fs-m-1_2em t-c-m t-r">
                                        {this.state.pretransaction._id}
                                    </div>
                                </div>
                                <div className="col-group p-0_5 p-m-0_5 border color6">
                                    <div className="col-5 color4 fw-400 t-c-m t-l">
                                        Date:
                                    </div>
                                    <div className="col-7 color4 fw-600 fs-m-1_2em t-c-m t-r">
                                       { this.state.pretransaction.date}
                                    </div>
                                </div>
                                <div className="col-group p-0_5 p-m-0_5 border color6">
                                    <div className="col-5 color4 fw-400 t-c-m t-l">
                                        Project Name:
                                    </div>
                                    <div className="col-7 color4 fw-600 fs-m-1_2em t-c-m t-r">
                                        {this.state.pretransaction.project_invested}
                                    </div>
                                </div>
                                <div className="col-group p-0_5 p-m-0_5 border color6">
                                    <div className="col-5 color4 fw-400 t-c-m t-l">
                                    Tons CO2 Offset:
                                    </div>
                                    <div className="col-7 color4 fw-600 fs-m-1_2em t-c-m t-r">
                                    {this.state.pretransaction.co2_emission}
                                    </div>
                                </div>
                                <div className="col-group p-0_5 p-m-0_5 border color6">
                                    <div className="col-7 color4 fw-400 t-c-m t-l">
                                    Amount Invested in USD:
                                    </div>
                                    <div className="col-5 color4 fw-600 fs-m-1_2em t-c-m t-r">
                                        {this.state.pretransaction.payment_amount}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-group ">
                        <div className="col-6 col-offset-3 pt-2 t-c">
                            <div className="pb-0_5 fs-1em color4">
                                Congratulations you are making the difference!!
                            </div>
                            <div className="pb-2 fs-1em color4">
                                Go Check your current situation
                            </div>
                            <Link to="/myaccount" className="bg4 color1 p-0_5 pl-2 pr-2 fs-0_8em fw-700 border-radius-12">
                                My Account
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="monodepie1"></div>
                <div className="monodepie2"></div>
            </div>
        );
    }
}
export default Offset4rewards
