import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import util from "../../../utils/util";
// import App from './App';
import api from "../../../utils/api";

import Section1 from "../../../components/Offset4rewards/Section1";
import Section2 from "../../../components/Offset4rewards/Section2";
import Section3 from "../../../components/Offset4rewards/Section3";
import Section4 from "../../../components/Offset4rewards/Section4";
import Section5 from "../../../components/Offset4rewards/Section5";
import Section6 from "../../../components/Offset4rewards/Section6";
import Section7 from "../../../components/Offset4rewards/Section7";
import Section8c from "../../../components/Offset4rewards/Section8c"; // success
import Section8d from "../../../components/Offset4rewards/Section8d"; // failed



export class Page1 extends Component {

  constructor(props) {
    super(props);
    console.log('constructor');
    

    this.state = {
      section: 2 | this.props.page,
      dataFlight: '', //data flight buscado 
      co2_enter: '', //co2 ingresado manualmente 
      projects: [],   // todos los proyectos
      selectProject: '', //proyecto seleccionado
      amount: '', // monto de inversion
      egreenCoin: '', // cantidad de egreen coin
      egreenCoinCostPerDollar : 2 ,// egren coin equivales a 2 dolares
      pretransaction: '',
    };
  }


  async getFinishPay(){
    console.log(this.state.section);
    if(this.state.section === 9) { return; }
    const id_pretransaction = this.props.match.params.session_id;
    const user = JSON.parse(localStorage.getItem("userSession"));
    console.log(user);
    console.log(id_pretransaction);
    console.log(id_pretransaction !==  undefined);
    console.log( user !== null);
    const pretransaction  = await api.pretransaction.getPretransaction(id_pretransaction);
    console.log(pretransaction.status !== 404);
    console.log(pretransaction);
    
    
    if( id_pretransaction !== undefined && pretransaction.status !== 404 && user !== null){
      console.log("cambio a pagina de exito");
      const id_session = pretransaction.pretransaction.id_session;
      console.log(id_session);
      let obj = {};
      obj.destiny = user.email;
      obj.language = 'ing';
      obj.type = 'payment';
      obj.pretransaction = pretransaction.pretransaction;
      console.log(obj);

       const sessionStripe = await api.stripe.checkPay({idSession: id_session, idPretransaction: id_pretransaction});
       console.log(sessionStripe);
      
      if(sessionStripe.session.payment_status === 'paid'){
        await this.setState({pretransaction: pretransaction.pretransaction, section: 8});
        // let d = new Date();
        // d = moment(d).format('L'); 
        // console.log(d);
        api.email.send(obj);
        await api.pretransaction.updatePretransaction(id_pretransaction,{state: 'accepted'});

      }
    }
  }

  saveDataFlight = data => {
    this.setState({ dataFlight: data });
  }

  saveProjects = data => { 
    console.error("En guardar proyectos");
    console.error(data);
    this.setState({ projects: data });
  }

  saveSelectProject = data => { 
    this.setState({ selectProject: data });
  }

  saveIconCo2 = data => { 
    console.log(data);
    this.setState({ iconCo2: data });
  }

  saveAmount = (data,co2) => { 
    console.log("CO2", co2);
    this.setState({ amount: data, egreenCoin: co2/process.env.REACT_APP_CO2_EGREEN, co2_enter:co2 });
  }

  async componentDidMount() {
    console.log('componentDidMount');
    let session = await localStorage.getItem("userSession");
    this.getFinishPay();
    console.log('session', session);

    this.setState({session}); 
  }

  setSection = id =>{
    this.setState({section: id});
  }

  getSection = e => {

    e = e || window.event;
    e = e.target || e.srcElement;
    //console.log(e.id);
    console.log(e);

    if (e.id === "section1") {
      this.setState({ section: 1 });
      console.log('id de boton getProject:', "=>", e.id);
    }
    if (e.id === "section2") {
      this.setState({ section: 2 });
      console.log('id de boton getProject:', "=>", e.id);
    }
    if (e.id === "section3") {
      this.setState({ section: 3 , iconCo2: e.name});
      console.log('id de boton getProject:', "=>", e.name);
    }
    if (e.id === "section4") {
      this.setState({ section: 4 , selectProject: e.name, iconTree: true});
      console.log('id de boton getProject:', "=>", e.id);
    }
    if (e.id === "section5") {
      this.setState({ section: 5, amount: e.amount, 
        egreenCoin: e.amount/this.state.egreenCoinCostPerDollar}); // aca paso amount
      console.log('id de boton getProject:', "=>", e.id);
    }
    if (e.id === "section6") {
      this.setState({ section: 6 });
      if(this.state.session)
      {
        this.setState({ section: 7 ,iconCard: true});
      }
      console.log('id de boton getProject:', "=>", e.id);
    }
    if (e.id === "section7") {
      this.setState({ section: 7, iconCard: true });
      console.log('id de boton getProject:', "=>", e.id);
    }
    if (e.id === "section8") {
      this.setState({ section: 8 });
      console.log('id de boton getProject:', "=>", e.id);
    }
  };

  setProject = e => {
    e = e || window.event;
    e = e.target || e.srcElement;
    console.log(e.dataset.keyclick);
  }


  render() {

    let section;
    switch (this.state.section) {
      case 1:
        return section = (<Section1 getSection={this.getSection} saveDataFlight={this.saveDataFlight} lan={this.props.lan}></Section1>);
      case 2:
        return section = (<Section2 getSection={this.getSection} lan={this.props.lan}></Section2>);
      case 3:
        return section = (<Section3 getSection={this.getSection}
          saveProjects={this.saveProjects}
          iconCo2={this.state.iconCo2}
          lan={this.props.lan}
        ></Section3>);
      case 4:
        return section = (<Section4 getSection={this.getSection} 
          selectProject={this.state.selectProject}
          saveSelectProject={this.saveSelectProject}
          projects={this.state.projects}
          iconCo2={this.state.iconCo2}
          lan={this.props.lan}
          ></Section4>);
      case 5:
        return section = (<Section5 getSection={this.getSection} 
          dataFlight={this.state.dataFlight}
          selectProject={this.state.selectProject}
          projects={this.state.projects}
          saveAmount={this.saveAmount}
          saveIconCo2={this.saveIconCo2}
          iconCo2={this.state.iconCo2}
          iconTree={this.state.iconTree}
          lan={this.props.lan}
          ></Section5>);
      case 6:
        return section = (<Section6 getSection={this.getSection}
          iconCo2={this.state.iconCo2}
          iconTree={this.state.iconTree}
          setSection= {this.setSection}
          lan={this.props.lan}
        ></Section6>);
      case 7:
        return section = (<Section7 getSection={this.getSection}
        amount={this.state.amount}
        egreenCoin={this.state.egreenCoin}
        selectProject={this.state.selectProject}
        projects={this.state.projects}
        dataFlight={this.state.dataFlight}
        co2_enter={this.state.co2_enter}
        iconCo2={this.state.iconCo2}
        iconTree={this.state.iconTree}
        lan={this.props.lan}
        ></Section7>);
      case 8:
        return section = (<Section8c getSection={this.getSection}
          pretransaction={this.state.pretransaction}
          lan={this.props.lan}
        ></Section8c>);
      case 9:
        return section = (<Section8d getSection={this.getSection}
          selectProject={this.state.selectProject}
          projects={this.state.projects}
          amount={this.state.amount}
          egreenCoin={this.state.egreenCoin}
          iconCo2={this.state.iconCo2}
          iconTree={this.state.iconTree}
          iconCard={this.state.iconCard}
          lan={this.props.lan}
        ></Section8d>);
      default:
        return section = (<Section2 getSection={this.getSection} saveData={this.saveData} lan={this.props.lan}></Section2>);

        // return section = (<Section6 getSection={this.getSection} saveData={this.saveData}></Section6>);
        // return section = (<Section8d getSection={this.getSection} saveData={this.saveData}></Section8d>);

    }


    return (
      <div className="container p-0">
        {section}
      </div>
    );
  }

}

export default withRouter(Page1);