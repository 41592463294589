import React, { Component } from 'react';
// import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons'

import { Link } from "react-router-dom";
import ReactPlayer from 'react-player/lazy'
import '../MainHome/MainHome.css';
import Zendesk from "react-zendesk";
import lan_en from "../../languages/en.json"
import lan_es from "../../languages/es.json"
const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;

class MainHomeC extends Component {

    constructor(props) {
        super(props);
        this.state = {
            explore1: false,
            explore2: false,
            playing : false,
            controls: true,
        };
         
       
    }
    componentDidMount() {
        document.title = "eGreen | Home";
        document.body.style.backgroundImage = "linear-gradient(to right, #0FA9C9, #5CC882)";
        window.scrollTo({ top: 0});
    }

    setExplore1 = () => {
        this.setState({explore1: !this.state.explore1});
    }
    
    setExplore2 = () => {
        this.setState({explore2: !this.state.explore2});
    }

    playVideo = () => {
        // alert("sdjfklsd");
        console.log("play");
        // setInterval(
        //     () => this.checkSession(),
        //     2000
        // );
        this.setState({playing: true});
    }

    pauseVideo = () => {
        // alert("sdjfklsd");
        console.log("pause");
        this.setState({playing: false});
    }

    render() {

        const { playing, controls } = this.state;

        return (
            <div className="pt-3 pt-m-2">
                <div className="container">

                    <div className="col-group">
                        <div className="col-4 col-offset-2">
                            <h2 className="montse color4 fs-2em fs-m-2em p-1 pt-1 p-m-1 pt-m-0_5 pb-0 pb-m-0">
                                {this.props.lan.Home.tittle1}
                                
                            </h2>
                            <p className="montse fs-1_2em color4 p-1 p-m-2 pt-2 pt-m-0_5 pb-0_5 pb-m-0_3 fw-500">
                                {this.props.lan.Home.tittle2}
                            </p>
                            {/* <p className="montse fs-1_2em color4 p-4 p-m-2 pt-0 pt-m-0 pb-4 pb-m-0 fw-500">
                                And much bigger reward when you offset someone else CO2 emissions.</p> */}

                            <div className="col-12 p-1 p-m-1">
                                <div className="col-7 col-offset-0 p-1 p-m-1 bg8 border-radius-12">

                                    <div className="container p-0">
                                        <div className="col-group t-c">

                                            <div className="col-12 fw-600 color3 fs-1em ">
                                                {this.props.lan.Home.tittle_egreen_coin}
                                            </div>
                                            <div className="col-12 fw-400 pb-1">
                                                <img src="/svg/egreenCoin.svg" width="80%" className="w-m-100 pt-1" />
                                            </div>

                                            <div className="col-10 col-offset-1 p-0_5 pr-0 pl-m-0 pr-m-0 pt-0 pt-m-0">
                                                <Link to="/offset4reward" className="pointer btn-calculate border-radius-12 gradient3 box-shadow t-c color4 fw-400 fs-2em" >
                                                    {this.props.lan.Home.go_invest}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-5 p-1 p-m-1 pt-2 border-radius-12">
                                    <span className="color4 fs-0_7em">{this.props.lan.Home.warning}</span>
                                </div>

                            </div>


                        </div>
                        <div className="col-6">
                            <img src="/svg/home3.svg" width="" className="w-m-100" />
                        </div>
                    </div>

                    <div className="col-group bg4 pt-2 pt-m-1 flex flex-wrap none-m" onMouseOver={this.pauseVideo}>
                        <div className="col-4 col-offset-2">
                            <h2 className="montse color1 fs-2_2em fs-m-2em p-2 pt-1 p-m-1 pt-m-0_5 pb-0 pb-m-0">
                                {this.props.lan.Home.eGreen_virtuous_loop}
                            </h2>
                            <p className="montse fs-1_2em color2 p-4 p-m-2 pt-2 pt-m-0_5 pb-0_5 pb-m-0_3 fw-500">
                                {this.props.lan.Home.t1_1}
                            </p>
                        </div>

                        {/* <div className="col-4 pt-2 pt-m-1 align-self-end">
                            <img src="/svg/loop/flechas/01.svg" width="" className="w-m-100" />
                        </div> */}
                    </div>
                    <div className="col-group bg4 pt-4 pt-m-1 none-m" onMouseOver={this.playVideo}>
                        
                        <div className="col-8 col-offset-2 p-2" >
                            <div className='player-wrapper video' >
                            <ReactPlayer 
                                    // url='VID-20201202-WA0023.mp4'
                                    //url='http://mvp.egreen.com/VID-20201202-WA0023.mp4'
                                    // url='http://209.105.243.7/VID-20201202-WA0023.mp4'
                                    url='https://vimeo.com/487036092'
                                    //url='https://www.dailymotion.com/video/x7xgw7k'
                                    // url={[
                                    //     { src: '/public/VID-20201202-WA0023.mp4', type: 'video/mp4' },
                                    //     { src: 'VID-20201202-WA0023.mp4', type: 'video/mp4' }
                                    // ]}
                                    className='react-player'
                                    loop
                                    // playing
                                    muted = {true}
                                    playing={playing}
                                    controls={false}
                                    width='100%'
                                    height='600px'
                                    onStart={() => console.log('onStart')}
                                    // onPlay={this.handlePlay}
                                    // onEnablePIP={this.handleEnablePIP}
                                    // onDisablePIP={this.handleDisablePIP}
                                    // onPause={this.handlePause}
                                    onBuffer={() => console.log('onBuffer')}
                                    onSeek={e => console.log('onSeek', e)}
                                    // onEnded={this.handleEnded}
                                    onError={e => console.log('onError', e)}
                                    // onProgress={this.handleProgress}
                                    // onDuration={this.handleDuration}
                            />
                            </div>
                        </div>
                         
                    </div>
                    <div className="col-group bg4 pt-4 pt-m-1 none-m" onMouseOver={this.playVideo}>
                        
                        <div className="col-8 col-offset-2 p-2" >
                            <div className='player-wrapper video' >
                            <ReactPlayer 
                                    // url='VID-20201202-WA0023.mp4'
                                    //url='http://mvp.egreen.com/VID-20201202-WA0023.mp4'
                                    // url='http://209.105.243.7/VID-20201202-WA0023.mp4'
                                    url='https://vimeo.com/487067874'
                                    // url='https://www.dailymotion.com/video/x7xgw7k'
                                    // url={[
                                    //     { src: '/public/VID-20201202-WA0023.mp4', type: 'video/mp4' },
                                    //     { src: 'VID-20201202-WA0023.mp4', type: 'video/mp4' }
                                    // ]}
                                    className='react-player'
                                    loop
                                    // playing
                                    muted = {true}
                                    playing={playing}
                                    controls={false}
                                    width='100%'
                                    height='600px'
                                    onStart={() => console.log('onStart')}
                                    onBuffer={() => console.log('onBuffer')}
                                    onSeek={e => console.log('onSeek', e)}
                                    onError={e => console.log('onError', e)}
                                    
                            />
                            </div>
                        </div>
                         
                    </div>
                    {/* <div className="col-group bg4 pt-2 pt-m-1 none-m">
                        <div className="col-4 col-offset-2 pl-2">
                           
                            <img src="/svg/loop/07.svg" width="" className="w-m-100" />
                        </div>
                        <div className="col-4 pt-2 pt-m-1">
                            <img src="/svg/loop/flechas/02.svg" width="" className="w-m-100" />
                        </div>
                    </div> */}
                    {/* <div className="col-group bg4 pt-2 pt-m-1 none-m">
                        <div className="col-4 col-offset-2 pl-2">
                            <img src="/svg/loop/flechas/06.svg" width="" className="w-m-100" />
                        </div>
                        <div className="col-4 pt-2 pt-m-1">
                            <img src="/svg/loop/flechas/03.svg" width="" className="w-m-100" />
                        </div>
                    </div> */}
                    {/* <div className="col-group bg4 pt-2 pt-m-1 none-m">
                        <div className="col-4 col-offset-2 pl-2">
                            <img src="/svg/loop/flechas/05.svg" width="" className="w-m-100" />
                        </div>
                        <div className="col-4 pt-2 pt-m-1 pb-2 pb-m-1">
                            <img src="/svg/loop/flechas/04.svg" width="" className="w-m-100" />
                        </div>

                    </div> */}
                    <div className="col-group bg4 pt-2 pt-m-1 none block-m">
                        <div className="col-4 col-offset-2">
                            <h2 className="montse color1 fs-2_2em fs-m-2em p-2 pt-1 p-m-1 pt-m-0_5 pb-0 pb-m-0">
                                {this.props.lan.Home.eGreen_virtuous_loop}
                            </h2>
                            <p className="montse fs-1_2em color2 p-4 p-m-2 pt-2 pt-m-0_5 pb-0_5 pb-m-0_3 fw-500">
                                A new solution to speed up toward Net Zero companies collaborating with people.
                            </p>
                        </div>

                        <div className="col-4 pt-2 pt-m-1">
                            <img src="/svg/loop/01.svg" width="" className="w-m-100" />
                        </div>

                        <div className="col-4 col-offset-2 pl-2">
                            <img src="/svg/loop/02.svg" width="" className="w-m-100" />
                        </div>
                        <div className="col-4 pt-2 pt-m-1">
                            <img src="/svg/loop/03.svg" width="" className="w-m-100" />
                        </div>

                        <div className="col-4 col-offset-2 pl-2">
                            <img src="/svg/loop/04.svg" width="" className="w-m-100" />
                        </div>
                        <div className="col-4 pt-2 pt-m-1">
                            <img src="/svg/loop/05.svg" width="" className="w-m-100" />
                        </div>

                        <div className="col-4 col-offset-2 pl-2">
                            <img src="/svg/loop/06.svg" width="" className="w-m-100" />
                        </div>
                        <div className="col-4 pt-2 pt-m-1 pb-2 pb-m-1">
                            <img src="/svg/loop/07.svg" width="" className="w-m-100" />
                        </div>

                    </div>

                    <div className="col-group pt-2 pt-m-1" onMouseOver={this.pauseVideo}>
                        <div className="col-4 col-offset-2">
                            <img src="/svg/carbon1.svg" width="70%" className="w-m-100" />
                        </div>
                        <div className="col-4">
                            <h2 className="montse color4 fs-2_2em fs-m-2em p-2 pt-1 p-m-1 pt-m-0_5 pb-0 pb-m-0">
                                {this.props.lan.Home.offset4rewards}
                            </h2>
                            <p className="montse fs-1_2em color4 p-4 p-m-2 pt-2 pt-m-0_5 pb-0_5 pb-m-0_3 fw-500">
                                {this.props.lan.Home.t2_1}
                                </p>
                            <div className="p-2 pt-0 pb-0_5 flex flex-center">

                                <div className="col-8 col-offset-0 p-1 p-m-1 bg8 border-radius-12">

                                    <div className="container p-0">
                                        <div className="col-group t-c">

                                            <div className="col-12 fw-600 color3 fs-1em ">
                                                {this.props.lan.Home.tittle_egreen_coin}
                                        </div>
                                            <div className="col-12 fw-400 pb-1">
                                                <img src="/svg/egreenCoin.svg" width="80%" className="w-m-100 pt-1" />
                                            </div>

                                            <div className="col-10 col-offset-1 p-0_5 pr-0 pl-m-0 pr-m-0 pt-0 pt-m-0">
                                                <Link to="/offset4reward" className="pointer btn-calculate border-radius-12 gradient3 box-shadow t-c color4 fw-400 fs-2em" >
                                                    {this.props.lan.Home.go_invest}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <p className="montse fs-1_2em color4 p-4 p-m-2 pt-0 pt-m-0 pb-4 pb-m-0 fw-500">
                                {this.props.lan.Home.t2_2}
                            </p>
                            
                        </div>
                    </div>

                    <div className="col-group pt-2 pt-m-1">
                        <div className="col-4 col-offset-2 pt-2 pt-m-1">
                            <h2 className="montse color4 fs-2_2em fs-m-2em p-2 pt-1 p-m-1 pt-m-0_5 pb-0 pb-m-0">
                                {this.props.lan.Home.why_carbon_offset}
                            </h2>
                            <div onClick={this.setExplore1} className="pointer col-10 p-0_5 pl-4 pr-4 flex align-item-center flex-center">
                                <span className="fw-500 color1 fs-1_4em" >{this.props.lan.Home.explore_more}</span>
                                <span className="material-icons pl-0_5 fs-1_4em fw-500 color1">arrow_forward_ios</span>
                            </div>
                        </div>
                        <div className="col-4 none-m">
                            <img src="/svg/carbon2.svg" width="70%" className="w-m-100" />
                        </div>
                    </div>
                    {  this.state.explore1 ? (
                                         <div className="col-group">
                                         <div className="col-8 col-offset-2 pt-2 pt-m-1 t-j">
                                        <h2 className="color4 fs-1_8em fs-m-1_4em pb-0_5 t-m-c ">{this.props.lan.Home.what_is_a_carbon_offset}</h2>
                                             <p className="color4 fs-1_2em pl-m-1 pr-m-1 pb-1 pl-2 ">
                                                {this.props.lan.Home.t3_1}
                                             </p>
                                            <p className="color4 fs-1_2em pb-1 pl-m-1 pr-m-1 pl-2 ">
                                                {this.props.lan.Home.t3_2}
                                             </p>
                                             <p  className="color4 fs-1_2em pb-1 pl-m-1 pr-m-1 pl-2 ">
                                                {this.props.lan.Home.t3_3}
                                             </p>
                                             <p  className="color4 t-c fs-1_2em fw-600 pb-1 pl-m-1 pr-m-1 pl-2 ">
                                                {this.props.lan.Home.t3_4}    
                                            </p>
                                             <p  className="color4 fs-1_2em pb-1 pl-m-1 pr-m-1 pl-2">
                                                {this.props.lan.Home.t3_5}                                             
                                            </p>
                                             <p  className="color1 fs-1_6em fs-m-1_4em pb-2 fw-500 t-m-c pl-m-1 pr-m-1 pl-2 ">
                                                {this.props.lan.Home.t3_6}                                             
                                            </p>
                 
                                             <h2 className="color4 fs-1_8em fs-m-1_4em pb-0_5 t-m-c">
                                                {this.props.lan.Home.why_eGreen_transforms}                                                                                       
                                            </h2>
                                             <p  className="color4 fs-1_2em pb-1 pl-m-1 pr-m-1 pl-2 ">
                                                {this.props.lan.Home.t4_1}                                            
                                            </p>
                                             <p  className="color4 fs-1_2em pb-2 pl-m-1 pr-m-1 fw-500 pl-2">
                                                {this.props.lan.Home.t4_2}                                              
                                            </p>
                 
                                             <h2 className="color4 fs-1_8em fs-m-1_4em pb-0_5 t-m-c">
                                                {this.props.lan.Home.who_are_the_potencial} 
                                             </h2>
                                             <p  className="color4 fs-1_2em pb-1 pl-m-1 pr-m-1 pl-2">
                                                {this.props.lan.Home.t5_1} 
                                             </p>
                                             <p  className="color4 fs-1_2em pb-2 pl-m-1 pr-m-1 pl-2">
                                                {this.props.lan.Home.t5_2} 
                                             </p>
                 
                                         </div>
                                     </div>
                 
                    ): ('') }
   
                    <div className="col-group pt-2 pt-m-1">
                        <div className="col-4 col-offset-2 pb-2 pb-m-1 none-m">
                            <img src="/svg/carbon3.svg" width="80%" className="w-m-100" />
                        </div>
                        <div className="col-4">
                            <h2 className="montse color4 fs-2_2em fs-m-2em p-2 pt-1 p-m-1 pt-m-0_5 pb-0 pb-m-0">
                                {this.props.lan.Home.get_a_profit} 
                            </h2>
                            <Link onClick={this.setExplore2} className="pointer col-10 p-0_5 pl-4 pr-4 pb-4 pb-m-3 flex align-item-center flex-center">
                                <span className="fw-500 color1 fs-1_4em">{this.props.lan.Home.explore_more} </span>
                                <span className="material-icons pl-0_5 fs-1_4em fw-500 color1">arrow_forward_ios</span>
                            </Link>
                        </div>
                    </div>
                   {this.state.explore2 ? (
                        <div className="col-group">
                            <div className="col-8 col-offset-2 pt-1 pt-m-1 t-j">
                            
                            <ul className="ul-loop pl-2 pr-2">
                                <li className="color4 fs-1_2em">
                                    {this.props.lan.Home.t6_1} 
                                </li>
                                <li className="color4 fs-1_2em">
                                    {this.props.lan.Home.t6_2}                                
                                </li>
                                <li className="color4 fs-1_2em">
                                    {this.props.lan.Home.t6_3}    
                                </li>
                                <li className="color4 fs-1_2em">
                                    {this.props.lan.Home.t6_4}
                                </li>
                            </ul>

                        </div>
                        <div className="col-8 col-offset-2 pt-1 pt-m-1 none-m pl-2 pr-2 pb-2 pb-m-1">
                            <img src="/svg/loop/v-loop.svg" width="100%" className="w-m-100" />
                        </div>
                        <div className="col-8 col-offset-2 pt-1 pt-m-1 none-d">
                            <img src="/svg/loop/v-loop-2.svg" width="100%" className="w-m-100" />
                        </div>
                    </div>

                   ): ('')}
                    <div className="col-group bg4 bg-sol-about pt-2 pt-m-1 pb-2">
                        <div className="col-8 col-offset-2 pl-2 pr-2 t-j">

                            <h2 className="montse color1 fs-2_2em fs-m-2em pt-1 p-m-1 pt-m-0_5 pb-0 pb-m-0">
                                {this.props.lan.Home.about_eGreen}
                            </h2>
                            {/* <p  className="color1 fs-1_2em fw-600 p-2 pt-1 p-m-1 pt-m-0_5 pb-0 pb-m-0">
                            A new solution to speed up toward Net Zero companies collaborating with people.
                            </p> */}
                            <p  className="color2 fs-1_2em p-2 pt-1 p-m-1 pt-m-0_5 pb-0 pb-m-0">
                                {this.props.lan.Home.t7_1}
                            </p>
                            <p  className="color2 fs-1_2em p-2 pt-1 p-m-1 pt-m-0_5 pb-0 pb-m-0">
                                {this.props.lan.Home.t7_2}
                            </p>
                            <p  className="color2 fs-1_2em p-2 pt-1 p-m-1 pt-m-0_5 pb-0 pb-m-0">
                                {this.props.lan.Home.t7_3}                           
                            </p>

                        </div>
                    </div>
                    <div className="col-group bg4 pt-2 pt-m-1 pb-2">
                        <div className="col-8 col-offset-2 pl-2 pr-2 flex flex-end">
                            <img src="/svg/great.svg" width="20%" className="w-m-40 p-0_5" />
                            <img src="/svg/magical.svg" width="20%" className="w-m-40 p-0_5" />
                            <img src="/svg/corfo.svg" width="20%" className="w-m-40 p-0_5" />
                        </div>
                    </div>
                    <Zendesk zendeskKey={ZENDESK_KEY}
                        // {...setting}
                        onLoaded={
                            () => console.log('is loaded')}></Zendesk>

                    <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=852b9c17-1915-4344-8625-5b2c627e8ddb"> </script>
                    
                    <div className="col-group bg-color-footer-home none-m">
                        <div className="t-c">
                            <img className="pb-2" width="60%" src="/svg/footer-home.svg"/>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}
export default MainHomeC;
