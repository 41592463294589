
const util = {

    financial(num, decimales = 2) {
        if (Number.isInteger(num)) {
            return num;
        }
        num = num.toString();
        num = num.slice(0, (num.indexOf("."))+decimales+1); 
        return Number.parseFloat(num).toFixed(decimales); 
    
    },

    redondeoDown(num, ajuste){
        return Number.parseFloat(Math.floor(num, ajuste));
    },
    
    redondeoUp(numero, decimales = 0) {
        let numeroRegexp = new RegExp('\\d\\.(\\d){' + decimales + ',}');   // Expresion regular para numeros con un cierto numero de decimales o mas
        if (numeroRegexp.test(numero)) {         // Ya que el numero tiene el numero de decimales requeridos o mas, se realiza el redondeo
            return Number(numero.toFixed(decimales));
        } else {
            return Number(numero.toFixed(decimales)) === 0 ? 0 : numero;  // En valores muy bajos, se comprueba si el numero es 0 (con el redondeo deseado), si no lo es se devuelve el numero otra vez.
        }
        }
}


export default util;