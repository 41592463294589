import React, { Component } from "react";
import { Form, Input } from "antd";
import BgProject from "./BgProject";
import  "../Offset4rewards/Offset4rewards.css";
import Breadcrumbs from './Breadcrumbs';
import util from "../../utils/util";

class Offset4rewards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flight: "",
            date: "",
            radio: 1,
            result: {},
            project: this.props.projects[this.props.selectProject],
            dataFlight: this.props.dataFlight,
            co2_enter: 1,
            amount: '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        console.log("componentWillReceiveProps", "=>", nextProps);
        if (nextProps) {
            this.setState({});
        }
    }
    componentDidUpdate(prevProps) {
        console.log(prevProps);
        if (this.props !== prevProps) {
            console.log("componentDidUpdate", "=>", this.props);
        }
        
    }

    async handleInputChange(event) {
        const target = event.target;
        console.log(target);
        const value = target.value;
        console.log(target.value);
        await this.setState({  co2_enter: value });
        this.calculateAmount();
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log("Received values of form: ", values);
            }

        });
    };

    handleSubmit2(event) {
        event.preventDefault();
        console.log(event);
        console.log(this.state.flight);
        console.log(
            "A name was submitted: " + this.state.flight + " - " + this.state.flight
        );
    }


    onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    calculateAmount = () => {
        console.log("onCalculate:");
        const co2 = (this.state.dataFlight.co2/1000) || this.state.co2_enter;
        if(co2 !== 0 || co2 !==  '' ){
            this.props.saveIconCo2(true);
        }else{
            this.props.saveIconCo2(false);
        }
        const ton_price = this.state.project.ton_price;
        const amountData = co2*ton_price;
        console.log(amountData);
        console.log(util.financial(amountData));
       
        
        this.setState({ amount: util.redondeoUp(amountData,2)});
        
        this.props.saveAmount(util.redondeoUp(amountData,2),co2);
    };

    componentDidMount() {
        document.title = "eGreen | Offset4reward - Invest in Project";
        window.scrollTo({ top: 0});
        console.log("Component did moiuunt")
        document.body.style.backgroundImage = "linear-gradient(to right, #0FA9C9, #5CC882)";
        console.log(this.state.dataFlight);
        console.log(this.state.project);
        this.calculateAmount();
    }

    render() {

        return (
            <>
            <Breadcrumbs iconCo2={this.props.iconCo2} iconTree={this.props.iconTree}/>
            <div className="general-container">
                <div className="offset4rewards container p-0">
                    {/* <StepComponent section={3}></StepComponent> */}
                    <div className="col-group pt-2 pt-m-1">
                    <div className="col-12 flex">
                    </div>
                        <div className="col-offset-2 col-4 col-mb-12 p-1 p-m-1 pt-m-0_5">

                        </div>
                        <div className="col-4 col-mb-12 p-0">
                            <div className="container">
                                <div className="col-group">
                                    <div className="col-12 p-1 p-m-2 pt-m-0">
                                        <div className="container">
                                            <div className="col-group">
                                                <div className="col-12 p-2 p-m-2 pt-m-1 bg4 border-radius-6 box-shadow">
                                                    <div className="container">
                                                        <div className="col-group p-0">

                                                            <div className="col-12 p-1 gradient2 border-radius-6 box-shadow">
                                                                <div className="container p-0">
                                                                    <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                                                        <div className="col-5 color4 fw-400 t-c-m">
                                                                            {this.props.lan.projects.carbon_credit_project}:{" "}
                                                                        </div>
                                                                        <div className="col-7 color4 fw-600 fs-m-1_2em t-c-m">
                                                                            {this.state.project.title}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                                                        <div className="col-5 color4 fw-400 t-c-m">
                                                                                {this.props.lan.projects.amount_to_invest}:{" "}
                                                                        </div>
                                                                        <div className="col-7 color4 fw-600 fs-m-1_2em t-c-m">
                                                                            {this.state.amount} USD
                                                                        </div>
                                                                    </div>

                                                                    { this.state.dataFlight ? (
                                                                    <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                                                        <div className="col-5 color4 fw-400 t-c-m">
                                                                            {this.props.lan.projects.co2_to_offset}:{" "}
                                                                        </div>
                                                                        <div className="col-7 color4 fw-600 fs-m-1_2em t-c-m">
                                                                        {this.state.dataFlight.co2} Kg/CO2
                                                                        </div>
                                                                    </div> ) :
                                                                    (
                                                                        <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                                                        <div className="col-5 color4 fw-400 t-c-m">
                                                                            {this.props.lan.projects.co2_to_offset}:{" "}

                                                                        </div>
                                                                        <div className="col-7 color4 fw-600 fs-m-1_2em t-c-m">
                                                                        {(<Input type="number" min="0"  max="1000"  className="input-text" value={this.state.co2_enter} onChange={ this.handleInputChange }/>)} 
                                                                        </div>
                                                                    </div> ) 
                                                                    }

                                                                    { this.state.dataFlight ? (
                                                                    <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                                                        <div className="col-5 color4 fw-400 t-c-m">
                                                                            {this.props.lan.projects.aircraft}:{" "}

                                                                        </div>
                                                                        <div className="col-7 color4 fw-600 fs-m-1_2em t-c-m">
                                                                        {this.state.dataFlight.aircraft}
                                                                        </div>
                                                                    </div> ) : ('') }

                                                                    { this.state.dataFlight ? (
                                                                    <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                                                        <div className="col-5 color4 fw-400 t-c-m">
                                                                            {this.props.lan.projects.co2_emission}:{" "}

                                                                        </div>
                                                                        <div className="col-7 color4 fw-600 fs-m-1_2em t-c-m">
                                                                        {this.state.dataFlight.co2} CO2 Kgs

                                                                        </div>
                                                                    </div> ) : ('') }

                                                                    <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                                                        <div className="col-12 t-c-m pt-1">
                                                                            <div
                                                                                id="section6"
                                                                                onClick={this.props.getSection}
                                                                                amount={this.state.amount}
                                                                                className="pointer col-12 t-c p-0_5 bg4 border-radius-6 fw-600 color3 box-shadow">
                                                                                    {this.props.lan.projects.invest}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            { this.state.dataFlight ? (
                                                            <div className="detalle-vuelos col-12 p-1">
                                                                <div className="container p-0">
                                                                    <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                                                        <div className="col-5 color1 fw-400 t-c-m">
                                                                            {this.props.lan.projects.flight_no}:{" "}
                                                                        </div>
                                                                        <div className="col-7 color1 fw-600 fs-m-1_2em t-c-m">
                                                                            {this.state.dataFlight.flight}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                                                        <div className="col-5 color1 fw-400 t-c-m">
                                                                            {this.props.lan.projects.date}:{" "}
                                                                        </div>
                                                                        <div className="col-7 color1 fw-600 fs-m-1_2em t-c-m">
                                                                        {this.state.dataFlight.date}

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-group p-0_5 p-m-0_5 pt-m-0">
                                                                        <div className="col-5 color1 fw-400 t-c-m">
                                                                            {this.props.lan.projects.ticket_type}:{" "}
                                                                        </div>
                                                                        <div className="col-7 color1 fw-600 fs-m-1_2em t-c-m">
                                                                            {this.props.lan.projects.one_way}
                                                                        </div>
                                                                    </div>
                                                                    

                                                                </div>
                                                            </div>
                                                            ) : ('') }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <BgProject />
            </div>
            </>
        );
    }
}
const Section1 = Form.create()(Offset4rewards);
export default Section1;
