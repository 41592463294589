import React from 'react'
import "./Cookies2.scss";



const Cookies = () => {

    const getCookie = localStorage.getItem('cookies');
    const [cookie, setCookie] = React.useState(getCookie);

    const acceptCookie = () => {
        localStorage.setItem('cookies', true);
        setCookie(true);
    }

    return (
        <> 
        {
        cookie ?  ('')
            : (
            <div id="cookies" className={cookie ? "none" : " "+"cookiesbox container p-4 p-m-2 vw-100"}>
                <div className="col-group">
                    <div className="col-6">
                        <h1 className="t-m-c">This website use cookies.</h1>
                        <p className="fs-0_8em t-m-c">
                            If you click on "Accept Cookies", you agree to cookies being stored on you device. We use cookies to improve website navigation and analyse website use. You can find detailed information in our <a className="fw-700 fs-1em" href="/docs/legal.pdf" target="_blank">Terms and Conditions</a>.
                        </p>
                    </div>
                    <div className="col-6 flex align-item-center flex-center pt-m-1 t-m-c">
                        <span onClick={()=>{ acceptCookie() }} className="btn-accept-cookie bg7 color4 p-1 fs-0_8em fw-500">
                            Accept Cookies
                        </span>
                    </div>
                </div>
            </div>
            )
        }
        </>
        
    )
}

export default Cookies
