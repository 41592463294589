import React, { Component } from "react";
import { Form, Input } from "antd";
import api from "../../utils/api";
/* import  '../Offset4rewards/Offset4rewards.css'; */

const FormItem = Form.Item;
class Offset4rewards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cargando:false,
            error: { show: false, message: '',status:'',token:'',refreshToken:''},
        };
        console.log(this.props.lan);
    }
    componentWillReceiveProps(nextProps) {
        console.log("componentWillReceiveProps", "=>", nextProps);
        if (nextProps) {
          this.setState({});
        }
      }
    login = (e) => {
        console.log("login",e);
        e.preventDefault();
        this.setState({cargando: true});
        // return;
    
        console.log(e);
        this.props.form.validateFields(async (err, values) => {
          console.log(values);
          if (!err) {
            console.log("Received values of form: ", values);
            let objet = {};
            objet.email = values.email1;
            objet.password = values.password1;
    
            const result = await api.user.signin(objet);
            console.log(result);
    
            if (result.token) {
              //SE SETA EL STATE CON EL RESULTADO 
              this.setState({
                error: {
                show: true, 
                message: result.message,
                refreshToken: result.refreshToken,
                token: result.token,
                status: result.status,
                cargando:false
                }
              });
    
              //SE SETEA EL USAER
              // setter
    
    
              let userSession = {
                id: result.id,
                email: values.email1,
                password: values.password1,
                token: result.token,
                refreshToken: values.refreshToken,
                name: result.name,
                lastName: result.lastname
              }
              localStorage.setItem('userSession', JSON.stringify(userSession));//RECAORDAR SACAR EL JSON PARA QUE SE OCULTE EL OBJETO EN EL NAVEGADOR 
              
            this.props.login();//cambie el section 
            this.props.setEstadoLogin(true);


            }
            else {
              //SE SETA EL STATE CON EL RESULTADO 

                 this.setState({error: {show: true, message: result.message  },cargando:false});
                 this.props.setEstadoLogin(false);
             /*  this.setState({
                error: {
                  show: true, message: result.message, refreshToken: result.refreshToken
                  , token: result.token, status: result.status
                }
              }); */
            }
    
    
    
    
    
    
    
    
    
            /*    if(result.token){
                   this.props.setSection(7);
               }else{
                 this.setState({error: {show: true, message: result.message }});
                 return;
               } */
            return result.errors;
          }
    
        });
      }
 

    render() {
        const  {getFieldDecorator}  = this.props.form;

        return (
        
            <div className="col-5 bg4 col-mb-12 p-1 pt-3 pt-m-1">
            <div className="container">
                <div className="col-group">
                    <div className="col-12 t-c pb-2">
                        <img src="/svg/login.svg" width="60%" />
                    </div>
                    <div className="col-12 t-c fs-2em fw-500 color1">
                      {this.props.lan.Account.login}
                    </div>
                    <Form name="login"  onSubmit={this.login2}>
                    <div className="col-10 col-offset-1 p-0 pt-1">
                        
                        <FormItem name="email1" className="">
                            <div className="gx-mb-3">
                                {getFieldDecorator("email1", {
                                rules: [
                                    {
                                    required: true,
                                    message:
                                        "Please, enter the email",
                                    },
                                ],
                                })(<Input placeholder={this.props.lan.Account.email} className="input-text" />)}
                            </div>
                        </FormItem>
                        
                    </div>

                    <div className="col-10 col-offset-1 p-0 pt-1">
                        
                        <FormItem name="password" className="">

                            <div className="gx-mb-3">
                                {getFieldDecorator("password1", {
                                rules: [
                                    {
                                    required: true,
                                    message:
                                        "Please, enter the password",
                                    },
                                ],
                                })(<Input className="input-text" placeholder={this.props.lan.Account.password} type="password" />)}
                            </div>
                        </FormItem>
                        
                    </div>

                    <div className="col-12 p-0 pt-1 pb-1">
                        <Form.Item className="p-0">
                      <div className="pointer btn-calculate p-0_5 border-radius-12 gradient3 box-shadow t-c color4" id="section7" htmlType="submit" onClick={this.login} >{this.props.lan.Account.my_account}</div>
                        </Form.Item>
                    </div>

                    

                </Form> 
                 
                </div>


                {  this.state.cargando===true ? (
                        <div className="col-offset-2 col-8 col-mb-12 p-0 pt-4 position-relative">
                          
                          <div id="loader" class="login loader">
                            <div class="loader-inner ball-scale-ripple-multiple">
                             
                              <div></div>
                              <div></div> 
                            </div>
                          </div>
                        
                        </div>
                        ):''}

                { this.state.error.show ? (
                            <div className="error-msg t-c fs-0_8em">  {this.state.error.message} status {this.state.error.status} </div>
                            ) : ('')
                    }
            </div>
        </div>

        );
    }
}
const Login = Form.create()(Offset4rewards);
export default Login;
