import React, { Component } from 'react';
import { Link } from "react-router-dom";
import "./BreadcrumbsScss.scss";


class Breadcrumbs extends Component{

    constructor(props) {
        super(props);
        this.state = {

        }
        //this.props.section = this.props.section;
        // this.pintados = {
        //     "section1":[{'a':'co1', 'e':'tree1','i':'card1','o':'flag1'}],
        //     "section2":[{'a':'co1', 'e':'tree1','i':'card1','o':'flag1'}],
        //     "section3":[{'a':'co2', 'e':'tree1','i':'card1','o':'flag1'}],
        //     "section4":[{'a':'co2', 'e':'tree1','i':'card1','o':'flag1'}],
        //     "section5":[{'a':'co2', 'e':'tree2','i':'card1','o':'flag1'}],
        //     "section6":[{'a':'co2', 'e':'tree2','i':'card1','o':'flag1'}],
        //     "section7":[{'a':'co2', 'e':'tree2','i':'card2','o':'flag1'}],
        //     "section8":[{'a':'co2', 'e':'tree2','i':'card2','o':'flag2'}],
        //     };
    }    
    
    componentDidMount() {
        // console.log('Section recived by Breadcrumbs:', this.props.section);
        // console.log('pintura:',this.props.section);
        // console.log('pintados:',this.pintados[this.props.section][0].a);
    }

    render() {
        return (
            <ul className="breadcrumbs">
                <li className="flex flex-column align-item-center">
                    <img className=""  src={ this.props.iconCo2 ? "/svg/icons/co2.svg" : "/svg/icons/co1.svg" }/>
                    <span className="fs-0_6em color2 t-c">knowing your C02.</span>
                </li>
                <li className="flex flex-column align-item-center">
                    <img className=""  src={ this.props.iconTree ? "/svg/icons/tree2.svg" : "/svg/icons/tree1.svg" }/>
                    <span className="fs-0_6em color2 t-c">Select your project.</span>
                </li>
                
                <li className="flex flex-column align-item-center">
                    <img className=""  src={ this.props.iconCard ? "/svg/icons/card2.svg" : "/svg/icons/card1.svg" }/>
                    <span className="fs-0_6em color2 t-c">Pay your offset.</span>
                </li>
                
                <li className="flex flex-column align-item-center">
                    <img className=""  src={ this.props.iconFlag ? "/svg/icons/flag2.svg" : "/svg/icons/flag1.svg" }/>
                    <span className="fs-0_6em color2 t-c">Receiving your reward</span>
                </li>
                
            </ul>
        )
    }
}
export default Breadcrumbs;