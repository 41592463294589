import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="container bg3 pb-0">
            <div className="col-group">
                <div className="col-6 col-offset-0 col-mb-12 pl-3 pb-1">
                    <img src="/svg/logo2.svg" width="40%" />
                    <a className="color4 block pt-1" href="/docs/legal.pdf" target="_blank">See our Terms and Conditions</a>
                </div>
                <div className="col-3 col-mb-12 p-1">
                    <ul  className="pt-1 t-l">
                        <li><Link to="/" className="color4">Home</Link></li>
                        <li><Link to="/offset4reward" className="color4">Offset4rewards</Link></li>
                        <li><Link to="/egrnxchange" className="color4">egrnXchange</Link></li>
                        <li><Link to="/partners" className="color4">Partners</Link></li>
                        {/* <li><span className="color4">Green Bonds</span></li> */}
                    </ul>
                </div>
                {/* <div className="col-2 col-mb-12 p-1">
                    <ul  className="pt-1 t-l">
                        <li><Link to="/" className="color4 pb-2">Contact</Link></li>
                        <li><Link to="/" className="color4">xxxxxx@egreen.com</Link></li>
                    </ul>
                </div> */}
                <div className="col-3 col-mb-12 p-1">
                    <ul  className="pt-1 pb-1 pt-3 t-l">
                        <li>
                            <a exact href={process.env.REACT_APP_FACEBOOK}  className="color4 pb-2">
                            Facebook
                            </a>
                        </li>
                        <li>
                            <a exact href={process.env.REACT_APP_INSTAGRAM}  className="color4 pb-2">
                                Instagram
                            </a>
                        </li>
                    
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Footer
