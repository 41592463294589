import React, { Component } from 'react';
import api from '../../utils/api';
import { Form, Input, Button, Radio, Tooltip } from "antd";
import BgClouds from './BgClouds';
import '../Offset4rewards/Offset4rewards.css';
import '../Offset4rewards/Offset4rewards.css';
import util from "../../utils/util";


import Breadcrumbs from './Breadcrumbs';

const FormItem = Form.Item;
class Offset4rewards extends Component{

    constructor(props) {
        super(props);
        this.state = {
            flight: '',
            date: '',
            radio: 1,
            result: {},
            iconCo2: false,
            calcCar : '',
            calcHome : '',
            calcFood : '',
            lan: this.props.lan
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        console.log(this.props.lan);
      }
    
      handleInputChange(event) {
          console.log(event);
          const target = event.target;
          console.log(target.name);
          const value = target.value;
          console.log(target.value);
          
          if(target.name === 'car'){
              let co2_calculated = value * process.env.REACT_APP_CALC_CAR_1 / process.env.REACT_APP_CALC_CAR_2;
              this.setState({ calcCar: util.financial(co2_calculated) });
          }
          if(target.name === 'home'){
            let co2_calculated = value * process.env.REACT_APP_CALC_HOME_1 / process.env.REACT_APP_CALC_HOME_2;
            console.log(co2_calculated);
              this.setState({ calcHome: util.financial(co2_calculated, 3) });
          }
          if(target.name === 'food'){
            let co2_calculated = (value * process.env.REACT_APP_CALC_FOOD_PERCENT) / process.env.REACT_APP_CALC_FOOD;
            this.setState({calcFood: util.financial(co2_calculated)});
          }

        
      }
    
        handleSubmit = e => {
            e.preventDefault()
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    console.log('Received values of form: ', values)
                }
            })
        }

      onChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
          radio: e.target.value,
        });
      };

      handleSubmit(event) {
        event.preventDefault();
        console.log(event);
        console.log(this.state.flight);
        console.log('A name was submitted: ' + this.state.flight + ' - ' + this.state.flight);
      }


       onFinish = async values =>  {
        console.log('Success:', values);
        const params = {
            id: values.flight
        };
        const response =  await api.flight.getFlight(params);
        const response2 =  await api.projects.getProjects();
        // const url = `https://my-json-server.typicode.com/mleve/egreen-co2-use-case/cars/${id}`;
        // console.log(url);
        // let response = await fetch(url);
        // response = await response.json();
        console.log(response2);
        this.setState({result: response});
      };
    
      onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
      };

      componentDidMount() {
        document.title = "eGreen | Offset4reward";
        window.scrollTo({ top: 0});
      }
      
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <>
          <Breadcrumbs iconCo2={this.state.iconCo2}  />
          <div className="general-container" >

                <div className="offset4rewards container p-0">
                    {/* <StepComponent section={1}></StepComponent> */}
                    <div className="col-group pt-2 pt-m-1">
                    <div className="col-12 flex">
                    
                    </div>



                        <div className="col-offset-2 col-4 col-mb-12 p-1 p-m-1 pt-m-0_5">

                            <div className="col-12 fs-2em fs-m-1_6em fw-500 t-c-m color1">
                                    {this.props.lan.Offset4rewards.tittle1}
                            </div>

                            <div className="col-12 pt-0_5 fs-2em fs-m-1_6em fw-500 color1">

                                <div className="col-6 p-0_5 pr-0 pl-m-0 pr-m-0 pt-0_2 pt-m-0 pb-0_2">
                                    <div className="btn-link-offset4rewards t-c p-0_2 bg5 active">
                                            {this.props.lan.Offset4rewards.impact_investment}
                                    </div>
                                </div>

                                    <div className="col-6 p-0_5 pr-0 pl-m-0 pr-m-0 pt-0_2 pt-m-0 pb-0_2">
                                        <span id="section1" className="btn-link-offset4rewards t-c p-0_2 bg5" onClick={this.props.getSection}>
                                            {this.props.lan.Offset4rewards.airlines}
                                        </span>
                                    </div>

                            </div>

                            {/* <div className="col-12 p-4 p-m-0_5 none-m">
                                <img className="avion" src="/svg/avion.svg"></img>
                            </div> */}

                        </div>
                        <div className="col-10 col-offset-1 col-mb-12 p-2 t-c">
                                        
                            <div className="container">
                                <div className="col-group p-1 p-m-0_5 pt-1 pt-m-0">
                                    {/* Primer container */}
                                    <div className="col-8 col-offset-0 p-1 p-m-1 gradient3 border-radius-12 go-invest">

                                        <div className="container p-0">
                                            <div className="col-group">

                                                <Form name="Calculadora" layout="inline" className="" onSubmit={this.handleSubmit}>
                                                    <div className="col-12 t-c pb-1 color4 fs-1em fs-m-0_8em fw-600 pb-0_5">
                                                            {this.props.lan.Offset4rewards.your_average}
                                                    </div>
                                                    
                                                    <div className="col-12 t-c color4 fs-1em fs-m-0_8em fw-400 pb-0_5">
                                                            {this.props.lan.Offset4rewards.tittle_cars}
                                                    </div>
                                                    
                                                
                                                    <div className="col-4 t-c color4 fs-1_4em fs-m-1_2em fw-400">

                                                        {(<Input name="car" type="number" min="0" max="1000" className="input-text" onChange={this.handleInputChange} />)} 

                                                    </div>
                                                        <div className="col-2 t-c color4 fs-1em fs-m-0_8em fw-400"> {this.props.lan.Offset4rewards.distance_unit} = </div>
                                                    <div className="col-4 t-c color4 fs-1_4em fs-m-1_2em fw-400">
                                                        <Input className="input-disabled input-text"  value={this.state.calcCar} disabled/>

                                                    </div>
                                                        <div className="col-2 t-c color4 fs-1em fs-m-1_2em fw-400">{this.props.lan.Offset4rewards.weight_unit}</div>
                                                    <br></br>

                                                    <div className="col-12 t-c color4 fs-1em fs-m-0_8em fw-400 pb-0_5">
                                                            {this.props.lan.Offset4rewards.tittle_house}
                                                    </div>
                                                    
                                                    <div className="col-4 t-c color4 fs-1_4em fs-m-1_2em fw-400">

                                                        {(<Input name="home" type="number" min="0" max="1000" className="input-text" onChange={this.handleInputChange} />)} 

                                                    </div>
                                                        <div className="col-2 t-c color4 fs-1em fs-m-1_2em fw-400"> {this.props.lan.Offset4rewards.money_unit} = </div>
                                                    <div className="col-4 t-c color4 fs-1_4em fs-m-1_2em fw-400">
                                                        <Input className="input-disabled input-text" value={this.state.calcHome} disabled />

                                                    </div>
                                                        <div className="col-2 t-c color4 fs-1em fs-m-1_2em fw-400">{this.props.lan.Offset4rewards.weight_unit}</div>
                                                        <br></br>

                                                    <div className="col-12 t-c color4 fs-1em fs-m-0_8em fw-400 pb-0_5">
                                                        {this.props.lan.Offset4rewards.tittle_food}

                                                    </div>
                                                    <div className="col-4 t-c color4 fs-1_4em fs-m-1_2em fw-400">

                                                        {(<Input name="food" type="number" min="0" max="1000" className="input-text" onChange={this.handleInputChange} />)} 

                                                    </div>
                                                        <div className="col-2 t-c color4 fs-1em fs-m-1_2em fw-400"> {this.props.lan.Offset4rewards.money_unit} = </div>
                                                    <div className="col-4 t-c color4 fs-1_4em fs-m-1_2em fw-400">
                                                        <Input className="input-disabled input-text" value={this.state.calcFood} disabled />

                                                    </div>
                                                        <div className="col-2 t-c color4 fs-1em fs-m-1_2em fw-400">{this.props.lan.Offset4rewards.weight_unit}</div>

                                                    <div className="col-12 t-c pt1 color4 fs-0_8em fs-m-1em fw-600">{this.props.lan.Offset4rewards.conversion} </div>
                                                    

                                                </Form>


                                            </div>
                                        </div>

                                    </div>
                                    {/* fin de primer container */}
                                
                                    {/* Segundo container */}
                                    <div className="col-4 col-offset-0 p-1 pt-2 p-m-1 bg4 border-radius-6 go-invest">

                                        <div className="container p-0">
                                            <div className="col-group">                                
                                                
                                                <div className="col-12 t-c color3 fw-400 pb-0">
                                                    <img src="/svg/egreenCoin.svg" width="70%" className="w-m-20 p-0_5" />
                                                </div>
                                                    
                                                <div className="col-8 col-offset-2 p-0_5 pr-0 pl-m-0 pr-m-0 pt-0_2 pt-m-0 pb-1">
                                                    <div id="section3" className="pointer btn-calculate p-0_5 border-radius-12 gradient3 fs-1_2em box-shadow t-c color4" onClick={this.props.getSection}>
                                                        {this.props.lan.Offset4rewards.go_invest}
                                                    </div>
                                                </div>

                                                <div className="col-10 col-offset-1 t-c color3 fs-0_6em fw-500">
                                                        {this.props.lan.Offset4rewards.warning}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {/*Fin Segundo container */}
                                    
                                    
                                    <div className="col-12 col-mb-12 col-offset-0 p-6 p-m-1 t-c fs-1_2em fs-m-1em">
                                            {this.props.lan.Offset4rewards.tittle2}
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <BgClouds />

            </div>
            </>
        )
    }
}
export default Form.create()(Offset4rewards);
