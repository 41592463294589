// import React, { useState, useEffect } from 'react'
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import './TopHeader.css';



class TopHeader extends Component{

  constructor(props) {
    super(props);
    this.state = {
        active: this.props.estadoLogin,
        check: '',
        text_lan: ""
    };
    
  }

  // let [active, setActive] = useState('');
  // const [check,setCheck] = useState();

  componentDidMount(){
    // code to run on component mount
    setInterval(
      () => this.checkSession(),
      2000
    );
    
  }  

  checkSession(){
    const session = localStorage.getItem("userSession");
    const lan = localStorage.getItem("lan");
    
    if(session!=null){
      // console.log('point active');
      this.setState({active:'point active'});
    }else{
      this.setState({active:'point'});
    }
    if(lan === 'es'){
      // console.log('point active');
      this.setState({text_lan:'SP'});
    }else{
      this.setState({text_lan:'EN'});
    }
  }

  setCheck() {
    document.getElementById("menu-btn").checked=false;
  }
  
  render(){
    return (

      <div className="header sticky">

        <div className="logo1">
          CSS Nav
        </div>

        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label className="menu-icon" htmlFor="menu-btn">
          <span className="navicon"></span>
        </label>

        <div className="menu montse">
          <NavLink to="/" exact onClick={this.setCheck}>
            Home
          </NavLink>
          <NavLink to="/offset4reward" exact onClick={() => this.setCheck(document.getElementById("menu-btn").checked=false)}>
            Offset4rewards
          </NavLink>
          <NavLink to="/egrnxchange" exact onClick={() => this.setCheck(document.getElementById("menu-btn").checked=false)}>
          egrnXchange
          </NavLink>
          <NavLink to="/partners" exact onClick={() => this.setCheck(document.getElementById("menu-btn").checked=false)}>
          Partners
          </NavLink>
          {/* <NavLink to="/greenbonds" exact onClick={() => setCheck(document.getElementById("menu-btn").checked=false)}>
          Green Bonds
          </NavLink> */}
          <NavLink to="/myaccount" exact onClick={() => this.setCheck(document.getElementById("menu-btn").checked=false)}>
          <span className={this.state.active}></span>My Account
          </NavLink>
          <div className="t-c pt-1" exact onClick={this.props.changeLanguage}>
          <span className="pointer btn-link-language p-0_2">{this.state.text_lan}</span>
          </div>

        </div>

      </div>
    )
  }
}

export default TopHeader
