import React, { useEffect, useRef } from 'react'
import { useSpring, animated } from 'react-spring'

const BgClouds = () => {
    const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
    const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10 + 50}px,0)`
    const trans2 = (x, y) => `translate3d(${x / 8}px,${y / 8 + 50}px,0)`
    const trans3 = (x, y) => `translate3d(${x / 6}px,${y / 6 + 50}px,0)`
    const trans4 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5 + 50}px,0)`
    // const trans5 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`

    const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 300, tension: 1500, friction: 840 } }))
    const myRef = useRef();
    useEffect(() => {
        if (myRef.current) {
          myRef.current.parentNode.onmousemove = ({ clientX: x, clientY: y }) => set({ xy: calc(x, y) });
        }
      }, [myRef])
    return (
        <div className="all-clouds gradient4" ref={myRef}>
            <animated.div className="cloud4" style={{ transform: props.xy.interpolate(trans1) }} />
            <animated.div className="cloud3" style={{ transform: props.xy.interpolate(trans2) }} />
            <animated.div className="cloud2" style={{ transform: props.xy.interpolate(trans3) }} />
            <animated.div className="cloud1" style={{ transform: props.xy.interpolate(trans4) }} />
        </div>
    )
}

export default BgClouds
