

import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
// import {withRouter} from 'react-router-dom';



import TopHeader from "../components/TopHeader/TopHeader";

import Offset4rewards from "../routes/main/offset4reward/page1";
import EgrnXchange from '../components/EgrnXchange';
import Partners from '../components/Partners';
import MyAccount from '../routes/main/myAccount/page1';
import GreenBonds from '../components/GreenBonds';
// import Landing from "../routes/main/landing/desktop";
// import MainHome from '../components/MainHome/MainHome';
import MainHomeC from '../components/MainHome/MainHomeC';
import Footer from '../components/Footer/Footer';

import Cookies from '../components/Cookies2/Cookies2';
import lan_en from "../languages/en.json"
import lan_es from "../languages/es.json"

class Router extends Component {

  constructor(props) {
    super(props);
    let lan = localStorage.getItem("lan");
    let language;

    console.log(lan);
    console.log(!lan);
    if (!lan) {
      localStorage.setItem('lan', 'en');
    }

    if (lan === 'es') {
      language = lan_es;
    } else {
      language = lan_en;
    }
    console.log(`lan_${lan}`);
    console.log(language);
    this.state = {
      estadoLogin: false,
      cookies: false,
      lan: language
    };
    //this.estadoLogin = this.estadoLogin.bind(this);
  }

  componentDidMount(){
    console.log('INDEX INDEX INDEDX');
  }

  setEstadoLogin  = estado => {
    console.log("cambio estado del login");
    console.log(estado);
    this.setState({estadoLogin: estado});
  }

  changeLanguage = () => {
    console.log(this.state.lan);
    console.log(lan_es);
    let lan = localStorage.getItem('lan');
    if (lan === 'es') {
      console.log("en ingles")
      localStorage.setItem('lan', 'en');
      this.setState({ 'lan': lan_en });
    } else {
      console.log("en español")
      localStorage.setItem('lan', 'es');
      this.setState({ 'lan': lan_es });
    }
    console.log(this.state.lan.Home.tittle1);
    // window.location.href = window.location.href;
  }

  render() {

    return (

      <BrowserRouter>
        <TopHeader estadoLogin={this.state.estadoLogin} changeLanguage={this.changeLanguage}></TopHeader>
        <Switch>

          <Route path='/offset4reward/:session_id' exact>
            <Offset4rewards lan={this.state.lan}/>
          </Route>
          <Route path='/offset4rewardfailed/:session_id' exact>
            <Offset4rewards page={9} lan={this.state.lan}/>
          </Route>
          <Route path='/offset4reward/' exact>
            <Offset4rewards lan={this.state.lan}/>
          </Route>

          {/* <Route path="/offset4rewardOk/:session_id">
            <Offset4rewards />
          </Route> */}

          <Route path="/egrnxchange" exact>
            <EgrnXchange />
          </Route>

          <Route path="/partners" exact>
            <Partners />
          </Route>

          <Route path="/myaccount" exact>
            <MyAccount setEstadoLogin={this.setEstadoLogin} lan={this.state.lan}/>
          </Route>

          <Route path="/greenbonds" exact>
            <GreenBonds />
          </Route>

          <Route path="/" exact>
            <MainHomeC lan={this.state.lan} />
          </Route>

        </Switch>
        <Footer></Footer>
        <Cookies></Cookies>
      </BrowserRouter>
    );
  }
}

export default Router;
