import React, { Component } from 'react';
import { Link } from "react-router-dom";
import api from '../../utils/api';
import Masonry from 'react-masonry-component';
import  '../Offset4rewards/Offset4rewards.css';
import Breadcrumbs from './Breadcrumbs';

class Offset4rewards extends Component{

    constructor(props) {
        super(props);
        this.state = {
            projects: [],
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.cargando = true;
      }
    
      handleInputChange(event) {
        const target = event.target;
        console.log(target.name);
        const value = target.name === 'oneWay' ? target.checked : target.value;
        console.log(target.value);
        const name = target.name;
        this.setState({
          [name]: value
        });

        
      }
    

      handleSubmit(event) {
        event.preventDefault();
        console.log(event);
        console.log(this.state.flight);
        console.log('A name was submitted: ' + this.state.flight + ' - ' + this.state.flight);
      }


    async componentDidMount() {
        document.title = "eGreen | Offset4reward - Green Projects";
        document.body.style.backgroundImage = "linear-gradient(to right, #0FA9C9, #5CC882)";
        window.scrollTo({ top: 0});

        let response =  [];
        response = await api.projects.getProjects();
        console.log(response);
        if(response){this.cargando = false;}
        console.log(this.cargando);
        // let temp = [{src: 'https://64.media.tumblr.com/4ae9011ac1ba28fe8b683fca9ae20f09/525af4a1ca7687f7-2f/s500x750/3e31c3ebf9e0fea99509d859a1026b83b313437e.jpg'
        // , title: 'Neque porro quisquam' 
        // , text:'Donec ac augue tempor, aliquam augue ut, ultricies velit.'
        // , link:'/'}];
        let temp =[];
        console.log(response.projects.length);
        
        response.projects.forEach(project => {
          let obj = {};
          let element = project.project;
          obj.id = element._id;  
          obj.src = 'https://64.media.tumblr.com/4ae9011ac1ba28fe8b683fca9ae20f09/525af4a1ca7687f7-2f/s500x750/3e31c3ebf9e0fea99509d859a1026b83b313437e.jpg';  
          if (element.images.length > 0)
          obj.src = element.images;
          obj.title = element.project_name;  
          obj.text = element.description;  
          obj.link = '/';
          obj.area = element.area;
          obj.biomass = element.biomass;
          obj.ton_co2_issued = element.ton_co2_issued;
          obj.ton_price = element.ton_price / process.env.REACT_APP_MONEY_FACTOR;
          obj.carbon_credit = project.info.data.available_bonds;
          temp.push(obj); 
        });
        console.log(temp);
        this.setState({projects: temp});
        this.props.saveProjects(temp);
    }
   /*  theProject = () =>{
      this.props.getProjects(this.props.setProject);
    } */


/* 
    getProjects = (e) => {

      e = e || window.event;
      e = e.target || e.srcElement;
      console.log(' ENTRE AAAAAAAAAAAAAA         getProjects', e.id);
      debugger;
   
    }; */

    getProjects(e){  
      e.preventDefault();
      e.stopPropagation();
      console.log(e.target.id)
   /*   this.props.offline()    */ 
    }
      
    render() {

        const childElements = this.state.projects.map((element, index) => {
            return (
              // <Link to={element.link} key={index} className="green-project col-4 col-mb-12 p-0_5 p-m-2 pt-m-0 pb-m-0">
              <Link data-keyclick={index} id="Section4" key={index} className="green-project col-4 col-mb-12 p-0_5 p-m-2 pt-m-0 pb-m-0">
                <div  className="col-12 p-1 bg4 box-shadow">
                  <div  className="col-12 max-height-m-8 p-0 block hidden pb-0_5 pb-m-0_5">
                    <img  src={element.src[0].url} className="w-100 max-height-12 max-height-m-8 p-0 cover" />
                  </div>
                  <h3  className="">{element.title}</h3>
                  <p  className="pb-1">{element.text}</p>
                  <Link onClick={this.props.getSection} className=" t-c p-0_5 bg1 border-radius-12  color4 fs-0_8em" name={index}  id="section4" > {this.props.lan.projects.see_details}</Link>
               </div> 
                  {/* <p>{index}</p> */}
           
              </Link>
            );
          });

        return (
          <>
            <Breadcrumbs iconCo2={this.props.iconCo2}  />
          <div className="general-container2" >

                <div className=" container p-0">
                {/* <StepComponent section={2}></StepComponent> */}
                    <div className="col-group pt-2 pt-m-1">

                        <div className="col-offset-2 col-4 col-mb-12 p-1 p-m-1 pt-m-0_5">
                            <div className="col-12 fs-2em fs-m-1_6em fw-500 t-c-m color4">
                                {this.props.lan.projects.tittle1}
                            </div>
                        </div>
                        {  this.cargando ? (
                        <div className="col-offset-2 col-8 col-mb-12 p-0 pt-4 position-relative">
                          
                          <div id="loader" class="loader">
                            <div class="loader-inner ball-scale-ripple-multiple">
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                          <div className="loading-text color4 t-c">Loading Projects</div>
                        </div>
                        ):''}
                        <Masonry className={"col-offset-2 col-8 col-mb-12 p-0 pb-4 hidden"}>
                          {childElements}
                        </Masonry>
                    </div>
                </div>

            </div>
          </>
        )
    }
}

export default Offset4rewards
