import React from 'react'

const Partners = () => {
    return (
        <div>
            Partners
        </div>
    )
}

export default Partners
