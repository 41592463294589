import React from 'react'

const GreenBonds = () => {
    return (
        <div>
            GreenBonds
        </div>
    )
}

export default GreenBonds
