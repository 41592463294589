import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import App from './App';

import Section6 from "../../../components/MyAccount/Section6";
/* import Login from "../../../components/MyAccount/Login";
import Register from "../../../components/MyAccount/Register"; */
import MyAccountS from '../../../components/MyAccount/MyAccount';
import api from "../../../utils/api";

export class Page1 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      section: 1,
    };
    console.log("__________________________________");
    console.log(this.props.lan);
  }



  componentDidMount() {

    let session = localStorage.getItem("userSession");
    console.log('session', session);

    if (session) {
      this.setState({ section: 1, session })
    }
    else {
      this.setState({ section: 2 })
    }

  }


  getSection = e => {

    e = e || window.event;
    e = e.target || e.srcElement;
    //console.log(e.id);
    console.log(e);

    if (e.id === "section1") {
      this.setState({ section: 1 });
      console.log('id de boton getProject:', "=>", e.id);
    }

  };


  logout=() => {
    localStorage.clear();
    /*  this.setState({
         user:{
             name:"",
             lastname:"",
             email:""
             
             
                }
       }); */
       this.setState({ section: 2 });
  }

  login=(e) => 
  {
    this.setState({ section: 1 });
  }


  render() {

    let section;
    switch (this.state.section) {
      case 1:
        return section = (<MyAccountS session={this.state.session} logout={this.logout}  lan={this.props.lan}></MyAccountS>);
      case 2:
        return section = (<Section6 getSection={this.getSection} login={this.login} setEstadoLogin={this.props.setEstadoLogin} lan={this.props.lan}></Section6>);

      default:
        return section = (<Section6 lan={this.props.lan}></Section6>);

    }


    return (
      <div className="container p-0">
        {section}
      </div>
    );
  }

}

export default withRouter(Page1);