import React, { Component } from "react";
import { Form } from "antd";
import BgAccount from "./BgAccount";

import "../Offset4rewards/Offset4rewards.css";
import '../Offset4rewards/Scss.scss';
import Breadcrumbs from './Breadcrumbs';
import Login from './Login';
import Register from './Register';

class Offset4rewards extends Component {
    constructor(props) {
        super(props);
        this.state = {
           register: {
                name: '',
                lasname: '',
                email: '',
                password: '',
            },
            login: {
                email: '',
                password: ''
            }

        };
    }

    

    componentDidMount() {
        console.log(this.props);
        document.title = "eGreen | Log In / Create Account";
        document.body.style.backgroundImage = "linear-gradient(to right, #0FA9C9, #5CC882)";
        window.scrollTo({ top: 0});
    }

    render() {
        

        return (
            <>
            <Breadcrumbs iconCo2={this.props.iconCo2} iconTree={this.props.iconTree} />
            <div className="general-container3">
                <div className="offset4rewards2 container">

                    <div className="col-group ">

                        <div className="col-6 col-offset-3 pt-2 ">
                            <div className="container"> 
                                <div class="full-height-flex flex col-group box-shadow">
                                
                                   <Login setSection={this.props.setSection} lan={this.props.lan}></Login>

                                    <Register register={this.register} lan={this.props.lan}></Register>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <BgAccount />
            </div>
            </>
        );
    }
}
const Section1 = Form.create()(Offset4rewards);
export default Section1;
